{
	"textures": [
		{
			"image": "kJump.png",
			"format": "RGBA8888",
			"size": {
				"w": 240,
				"h": 959
			},
			"scale": 1,
			"frames": [
				{
					"filename": "kJump1.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 157,
						"h": 248
					},
					"spriteSourceSize": {
						"x": 3,
						"y": 88,
						"w": 136,
						"h": 160
					},
					"frame": {
						"x": 1,
						"y": 1,
						"w": 136,
						"h": 160
					}
				},
				{
					"filename": "kJump11.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 157,
						"h": 248
					},
					"spriteSourceSize": {
						"x": 3,
						"y": 88,
						"w": 136,
						"h": 160
					},
					"frame": {
						"x": 1,
						"y": 1,
						"w": 136,
						"h": 160
					}
				},
				{
					"filename": "kJump4.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 157,
						"h": 248
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 19,
						"w": 99,
						"h": 194
					},
					"frame": {
						"x": 139,
						"y": 1,
						"w": 99,
						"h": 194
					}
				},
				{
					"filename": "kJump9.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 157,
						"h": 248
					},
					"spriteSourceSize": {
						"x": 25,
						"y": 38,
						"w": 123,
						"h": 165
					},
					"frame": {
						"x": 1,
						"y": 163,
						"w": 123,
						"h": 165
					}
				},
				{
					"filename": "kJump5.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 157,
						"h": 248
					},
					"spriteSourceSize": {
						"x": 4,
						"y": 4,
						"w": 108,
						"h": 192
					},
					"frame": {
						"x": 126,
						"y": 197,
						"w": 108,
						"h": 192
					}
				},
				{
					"filename": "kJump2.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 157,
						"h": 248
					},
					"spriteSourceSize": {
						"x": 13,
						"y": 39,
						"w": 111,
						"h": 197
					},
					"frame": {
						"x": 1,
						"y": 330,
						"w": 111,
						"h": 197
					}
				},
				{
					"filename": "kJump8.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 157,
						"h": 248
					},
					"spriteSourceSize": {
						"x": 32,
						"y": 14,
						"w": 125,
						"h": 190
					},
					"frame": {
						"x": 114,
						"y": 391,
						"w": 125,
						"h": 190
					}
				},
				{
					"filename": "kJump6.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 157,
						"h": 248
					},
					"spriteSourceSize": {
						"x": 17,
						"y": 0,
						"w": 107,
						"h": 201
					},
					"frame": {
						"x": 1,
						"y": 529,
						"w": 107,
						"h": 201
					}
				},
				{
					"filename": "kJump10.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 157,
						"h": 248
					},
					"spriteSourceSize": {
						"x": 15,
						"y": 57,
						"w": 126,
						"h": 171
					},
					"frame": {
						"x": 110,
						"y": 583,
						"w": 126,
						"h": 171
					}
				},
				{
					"filename": "kJump7.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 157,
						"h": 248
					},
					"spriteSourceSize": {
						"x": 13,
						"y": 0,
						"w": 117,
						"h": 201
					},
					"frame": {
						"x": 1,
						"y": 756,
						"w": 117,
						"h": 201
					}
				},
				{
					"filename": "kJump3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 157,
						"h": 248
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 17,
						"w": 112,
						"h": 202
					},
					"frame": {
						"x": 120,
						"y": 756,
						"w": 112,
						"h": 202
					}
				}
			]
		}
	],
	"meta": {
		"app": "https://www.codeandweb.com/texturepacker",
		"version": "3.0",
		"smartupdate": "$TexturePacker:SmartUpdate:4483d16a9405e0cc2902b9f326c9398f:5eb8a8d0018432fd87f1e24247ffe2d9:7730fd1e34f492c0d5faead29898176c$"
	}
}
