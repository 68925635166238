interface StateConfig{
    name?: string
    onEnter?: () => void,
    onUpdate?: (dt: number) => void,
    onExit?: () => void
}

export default class StateMachine
{
    private context?: any
    private states = new Map<string, StateConfig>()
    private currentState?: StateConfig
    private previousState?: StateConfig
    private name: string
    private isSwitchingState = false
    private stateQueue: string[] = []

    get previousStateName() {
        if (!this.previousState) {
            return ''
        }
        return this.previousState.name
    }

    isCurrentState(name: string) {
        if(!this.currentState) {
            return false
        }
        else return this.currentState.name === name
    }

    constructor(context?: any, name?: string) {
        this.context = context
        this.name = name ?? 'machine'
    }

    addState(name: string, config?: StateConfig){
        this.states.set(name, {
            name,
            onEnter: config?.onEnter?.bind(this.context),
            onUpdate: config?.onUpdate?.bind(this.context),
            onExit: config?.onExit?.bind(this.context)
        })
    }

    setState (name: string) {
        if (!this.states.has(name)) {
            console.warn(`Tried to change to unknown state ${name}`)
            return
        }

        if(this.isSwitchingState) {
            this.stateQueue.push(name);
            return
        }
        this.isSwitchingState = true

        if(this.name === 'santaSpawn') console.log(`[StateMachine (${this.name})] change from ${this.currentState?.name ?? 'none'} to ${name}`)

        if(this.currentState && this.currentState.onExit) {
            this.currentState.onExit()
        }
        this.previousState = this.currentState
        this.currentState = this.states.get(name)

        if(this.currentState && this.currentState.onEnter) {
            this.currentState.onEnter()
        }
        
        this.isSwitchingState = false
    }

    update(dt: number) {
        if(!this.currentState) {
            return
        }
        if(this.currentState.onUpdate){
            this.currentState.onUpdate(dt)
        }

        if(this.stateQueue.length > 0) {
            this.setState(this.stateQueue.shift()!)
            return
        }
        
    }
}