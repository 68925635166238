import Phaser from "phaser"
import ObstaclesController from "../scenes/ObstaclesController"
import { sharedInstance as events } from "../scenes/EventCenter"
import StateMachine from "../statemachine/StateMachine"
import Matter from "matter-js"
type CursorKeys = Phaser.Types.Input.Keyboard.CursorKeys
export default class PlayerController {
    private camera: Phaser.Cameras.Scene2D.Camera
    private scene: Phaser.Scene
    private sprite: Phaser.Physics.Matter.Sprite
    private stateMachine: StateMachine
    private cursors: CursorKeys;
    private pauseUpdates = false;
    private idleCycleCount = 0;
    private obstacles: ObstaclesController
    private health = 3
    private lastEnemy?: Phaser.Physics.Matter.Sprite
    private hitLock = false;
    private battleMode = false;
    private dialogCount = 0
    private dialogPause = true;
    private currentQuote?: Phaser.GameObjects.Image
    private currentText?: Phaser.GameObjects.BitmapText
    private fpsOffset = 1000/60
    private dialog = [
        {
            text: "\"You must be the\n\ndasterdily divant who\n\nturned the North Pole\n\nagainst me!\"\n\n\n\n\n  <Spacebar to cont.>"
        },
        {
            text: "\"MUHAHAHAHA! YES!\n\nIt is I, Krampus,\n\nhere to ruin the\n\nholidays!\"\n\n\n\n\n  <Spacebar to cont.>"
        },
        {
            text: "\"Sure, but like...\n\nWhy...\n\nSpecifically?\"\n\n\n\n\n\n\n  <Spacebar to cont.>"
        },
        {
            text: "\"If I'm being honest,\n\nI'm just kinda\n\ngenerically evil.\n\nKoopa-esque, if you\n\nwill.\"\n\n\n  <Spacebar to cont.>"
        },
        {
            text: "\"Gotcha. Well, it\n\nseems I must smite\n\nyou. Prepare to be\n\nsmote... Is it smote\n\nor smitten? Whatever.\"\n\n\n  <Spacebar to cont.>"
        }
    ]

    constructor(scene: Phaser.Scene, sprite: Phaser.Physics.Matter.Sprite, camera: Phaser.Cameras.Scene2D.Camera, cursors: CursorKeys, obstacles: ObstaclesController) {
        this.sprite = sprite
        this.cursors = cursors
        this.obstacles = obstacles
        this.scene = scene
        this.camera = camera

        this.camera.startFollow(this.sprite)

        this.createAnimations()
        this.stateMachine = new StateMachine(this, 'player')

        this.stateMachine.addState('idle1', {
            onEnter: this.idleOnEnter,
            onUpdate: this.idleOnUpdate
        })
        this.stateMachine.addState('run', {
            onEnter: this.runOnEnter,
            onUpdate: this.runOnUpdate
        })
        this.stateMachine.addState('jump', {
            onEnter: this.jumpOnEnter,
            onUpdate: this.jumpOnUpdate,
        })
        this.stateMachine.addState('killHop', {
            onEnter: this.killHopOnEnter,
            onUpdate: this.killHopOnUpdate,
        })
        this.stateMachine.addState('flip', {
            onEnter: this.flipOnEnter,
            onUpdate: this.flipOnUpdate,
        })
        this.stateMachine.addState('land', {
            onEnter: this.landOnEnter,
            onUpdate: this.landOnUpdate
        })
        this.stateMachine.addState('fall', {
            onEnter: this.fallOnEnter,
            onUpdate: this.fallOnUpdate
        })
        this.stateMachine.addState('crouch', {
            onEnter: this.crouchOnEnter,
            onUpdate: this.crouchOnUpdate
        })
        this.stateMachine.addState('santaHit', {
            onEnter: this.santaHitOnEnter,
            onUpdate: this.santaHitOnUpdate
        })
        this.stateMachine.addState('santaHitTop', {
            onEnter: this.santaHitOnEnterTop
        })
        this.stateMachine.addState('walkToPreBattle', {
            onEnter: this.walkToPreBattleOnEnter,
            onUpdate: this.walkToPreBattleOnUpdate
        })
        this.stateMachine.addState('preBattle', {
            onEnter: this.preBattleOnEnter,
            onUpdate: this.preBattleOnUpdate
        })
        this.stateMachine.addState('battle', {
            onEnter: this.battleOnEnter,
            onUpdate: this.battleOnUpdate
        })
        this.stateMachine.setState('fall')
        this.setupCollider()
    }

    init() {
        this.idleCycleCount = 0;
        this.health = 3
        this.hitLock = false;
        this.battleMode = false;
        this.dialogCount = 0
        this.dialogPause = true;
    }

    setupCollider() {
        this.sprite.setOnCollide((data: MatterJS.ICollisionPair) => {
            const bodyA = data.bodyA as MatterJS.BodyType
            const gameObjectA = bodyA.gameObject
            const bodyB = data.bodyB as MatterJS.BodyType
            const gameObjectB = bodyB.gameObject

            if(gameObjectA instanceof Phaser.Physics.Matter.TileBody || gameObjectB instanceof Phaser.Physics.Matter.TileBody){
                if (this.stateMachine.isCurrentState('jump') || this.stateMachine.isCurrentState('flip')|| this.stateMachine.isCurrentState('fall')) {
                    this.stateMachine.setState('land')
                }
            return
            }
            if(this.obstacles.is('killZone', bodyA) || this.obstacles.is('killZone', bodyB)) {
                this.health = 0
                events.emit('health-changed', this.health)
                events.emit('game-over-man')
                return
            }
            if(this.obstacles.is('car', bodyA) || this.obstacles.is('car', bodyB)) {
                if (this.stateMachine.isCurrentState('jump') || this.stateMachine.isCurrentState('flip')|| this.stateMachine.isCurrentState('fall')) {
                    this.stateMachine.setState('land')
                }
                return
            }

            const spriteA = gameObjectA as Phaser.Physics.Matter.Sprite
            const spriteB = gameObjectB as Phaser.Physics.Matter.Sprite
            const sprite = spriteA.getData('type') === 'santaSpawn' ? spriteB : spriteA;
            const body = spriteA.getData('type') === 'santaSpawn' ? bodyB : bodyA;

            const santaBottom = this.sprite.getBottomLeft().y;
            const obstacleTop = sprite.getTopLeft().y
            
            if(this.obstacles.is('snowman', body) || this.obstacles.is('ginger', body)
                || this.obstacles.is('elf', body) || this.obstacles.is('krampus', body)
                || this.obstacles.is('krampusJump', body)) { 
                const isHittable = !sprite.getData('hitPause')
                if(!sprite.visible) {
                    return
                }
                if(santaBottom < obstacleTop+30 && !isHittable) {
                    // hopped on unhittable enemy
                    this.stateMachine.setState('santaHitTop')
                    return
                } else if(santaBottom < obstacleTop+30) {
                    // santa is on top of the enemy
                    this.lastEnemy = body.gameObject;
                    this.stateMachine.setState('killHop')
                    events.emit(`kill-${sprite.getData('type')}`, this.lastEnemy)
                } else {
                    // hit by enemy
                    this.stateMachine.setState('santaHit')
                    return
                }
            } else if(this.obstacles.is('fire', body)) {
                // hit by enemy by fire
                if(!this.stateMachine.isCurrentState('crouch')) this.stateMachine.setState('santaHit')
                return
            } else if(this.obstacles.is('ornament', body)) {
                if(!sprite.getData("broken")) {
                    this.lastEnemy = body.gameObject;
                    sprite.setData("broken", true)
                    this.stateMachine.setState('santaHit')
                }
                return
            } else if(this.obstacles.is('present', body)) {
                console.log(santaBottom, obstacleTop)
                console.log(obstacleTop - santaBottom)
                if(santaBottom < obstacleTop+145) {
                    this.stateMachine.setState('land') 
                } else if(!sprite.getData('collected')){
                    const santaTop = this.sprite.getTopLeft().y;
                    const obstacleBottom = sprite.getBottomLeft().y
                    if(santaTop+15 > obstacleBottom) {
                        sprite.setData('collected', true)
                        events.emit('present-collected', sprite)
                    }
                }
            }        
        })

        this.sprite.on('animationcomplete', (animation, frame) => {
            this.pauseUpdates = false
        }, this);
    }

    update(dt: number) {
        this.stateMachine.update(dt)
    }

    private enableHits(){
        this.hitLock = false;
    }

    private animateHit() {
        if(!this.hitLock) {
            this.hitLock = true
            this.health--;
            if(this.health >= 0) {
                events.emit('health-changed', this.health)
            }
            if(this.health === 0) {
                events.emit('game-over-man')
                return
            }
            this.scene.time.addEvent({delay: 1000, callbackScope: this, callback: this.enableHits})
        }

        const startColor = Phaser.Display.Color.ValueToColor(0xffffff)
        const endColor = Phaser.Display.Color.ValueToColor(0xff0000)
        this.scene.tweens.addCounter({
            from: 0,
            to: 100,
            duration: 100,
            repeat: 5,
            yoyo: true,
            ease: Phaser.Math.Easing.Sine.InOut,
            onUpdate: tween => {
                const value = tween.getValue()
                const colorObject = Phaser.Display.Color.Interpolate.ColorWithColor(
                    startColor,
                    endColor,
                    100,
                    value
                )
                const color = Phaser.Display.Color.GetColor(
                    colorObject.r,
                    colorObject.g,
                    colorObject.b
                )
                this.sprite.setTint(color)
                this.pauseUpdates = false;
            }
        })
    }

    private santaHitOnEnterTop(){
        this.animateHit()
        this.stateMachine.setState('jump')
    }

    private santaHitOnEnter(){
        try {
            if(this.lastEnemy && this.sprite) {
                if(this.sprite.x < this.lastEnemy.x){
                    this.sprite.setVelocityX(-40)
                } else {
                    this.sprite.setVelocityX(40)
                }
            }
        } catch (error) {
            console.log(error)
        }
        this.pauseUpdates = true;
        this.animateHit()    
    }

    private santaHitOnUpdate(){
        if(!this.pauseUpdates) this.stateMachine.setState('idle1')
    }

    private crouchOnEnter(){
        this.pauseUpdates = true
        this.sprite.play('crouch1')
    }


    private crouchOnUpdate(){
        if(!this.cursors.down.isDown) {
            this.stateMachine.setState('idle1')
        } else if(!this.pauseUpdates) {
            this.sprite.play('crouch2', true)
        }
    }

    private idleCycle(){
        if(!this.pauseUpdates){
            this.pauseUpdates = true;
            this.sprite.play('idle' + this.idleCycleCount, true)
            if(this.idleCycleCount <= 2) {
                this.idleCycleCount += 1
            } else {
                this.idleCycleCount += -1
            }
        }
    }

    private idleOnEnter(){
        this.pauseUpdates = false;
        this.idleCycleCount = 1;
        this.sprite.setVelocityX(0)
    }

    private idleOnUpdate() {
        if (!this.stateMachine.isCurrentState('jump') && (this.cursors.left.isDown || this.cursors.right.isDown)) {
            this.stateMachine.setState('run')
        } else if(this.checkForJump()){
            this.stateMachine.setState('jump')
        } else if(this.cursors.down.isDown) {
            this.stateMachine.setState('crouch')
        } else {
            this.idleCycle()
        }
    }

    private getFpsAdjustmet(dt: number) {
        return dt/this.fpsOffset
    }

    private adjustVelocityX(dt: number) {
        const speed = 5 * this.getFpsAdjustmet(dt)
        if (this.cursors.left.isDown)
        {
            this.sprite.flipX = true // flip the sprite to the left
            if(this.battleMode && this.sprite.x < 11600){
                this.sprite.setVelocityX(0)
                return
            }
            this.sprite.setVelocityX(-speed)
            
        }
        else if (this.cursors.right.isDown)
        { 
            this.sprite.flipX = false // flip the sprite to the right
            if(this.battleMode && this.sprite.x > 12660){
                this.sprite.setVelocityX(0)
                return
            }
            this.sprite.setVelocityX(speed)
        } else {
            this.sprite.setVelocityX(0)
        }
    }

    private checkForJump () {
        return Phaser.Input.Keyboard.JustDown(this.cursors.space);
    } 

    private runOnEnter(){
        this.sprite.play('run')
    }

    private runOnUpdate(dt: number){
        if(!this.battleMode && this.sprite.x > 11250) {
            this.stateMachine.setState('walkToPreBattle')
        } else {
            this.adjustVelocityX(dt);
            if(this.checkForJump()){
                this.stateMachine.setState('jump')
            } else if (!this.cursors.left.isDown && !this.cursors.right.isDown){
                this.stateMachine.setState('idle1')
            }
        }
    }

    private walkToPreBattleOnEnter(){
        this.sprite.play('run')
        this.sprite.flipX = false
        var mySound = this.scene.sound.get('theme')
        this.scene.tweens.add({
            targets:  mySound,
            volume:   0,
            duration: 2500,
        });

    }

    private walkToPreBattleOnUpdate(dt: number){
        if(this.sprite.x > 12120) {
            this.sprite.play('idle4')
            this.sprite.setVelocityX(0)
            this.sprite.setVelocityY(0)
            this.sprite.flipX = false
            this.stateMachine.setState('preBattle')
        } else {
            const speed = 5 * this.getFpsAdjustmet(dt)
            this.sprite.setVelocityX(speed)
        }
    }

    private preBattleOnEnter(){
        this.scene.cameras.main.stopFollow()
        this.dialogCount = 0;
        this.currentQuote = this.scene.add.image(this.sprite.x+225, this.sprite.y-275, 'bubble')
        this.currentQuote.flipX = true
        console.log(this.dialog[this.dialogCount].text);
        this.currentText = this.scene.add.bitmapText(this.currentQuote.x-188, this.currentQuote.y-107, 'pixelFont', this.dialog[this.dialogCount].text, 15).setTint(0x660000)
        this.dialogCount++;
    }

    private preBattleOnUpdate(){
        if(this.dialogCount > 5 && !this.dialogPause) {
            this.currentQuote?.destroy()
            this.currentText?.destroy()
            this.stateMachine.setState('battle')
        } else {
            if(this.cursors.space.isDown && !this.dialogPause) {
                if (this.dialogCount <= 4){
                    this.currentText?.destroy()
                    const flip = (this.dialogCount % 2 === 0)
                    console.log(this.dialog[this.dialogCount].text);
                    const x = this.currentQuote ? this.currentQuote.x : 0
                    const y = this.currentQuote ? this.currentQuote.y : 0
                    this.currentText = this.scene.add.bitmapText(x-188, y-107, 'pixelFont', this.dialog[this.dialogCount].text, 15).setTint(0x660000)
                    if (this.currentQuote) {
                        this.currentQuote.flipX = flip
                    }
                }
                this.dialogPause = true
                this.dialogCount++;
            } else if(this.cursors.space.isUp && this.dialogPause) {
                this.dialogPause = false
            }
        }
        
    }
    
    private jumpback = false
    private battleOnEnter(){
        events.emit(`boss-fight`)
        this.scene.sound.play('bossTheme', {
            loop: true
        })
        this.pauseUpdates = true;
        //jumpBack and start final battle
        this.battleMode = true
        this.sprite.play('fall2')
        this.sprite.chain(['land','idle4'])
        this.scene.time.addEvent({delay: 2000, callbackScope: this, callback: () => this.pauseUpdates = false})
        this.jumpback = true
    }

    private battleOnUpdate(dt){
        if(!this.pauseUpdates) {
            this.battleMode = true;
            this.idleCycleCount = 1;
            this.stateMachine.setState('idle1')
        } else if(this.jumpback){
            this.jumpback = false
            this.sprite.setVelocityY(-20 * this.getFpsAdjustmet(dt))
            this.sprite.setVelocityX(-7 * this.getFpsAdjustmet(dt))
        }
    }

    private justHopped = false
    private killHopOnEnter() {
        this.scene.sound.play('enemyHit')
        this.pauseUpdates = true
        this.sprite.play('jump', true)
        this.justHopped = true
    }

    private killHopOnUpdate(dt) {
        if(this.justHopped) {
            this.justHopped = false
            const speed = (this.battleMode ? -20 : -3) * this.getFpsAdjustmet(dt)
            this.sprite.setVelocityY(speed)
        }
        if(!this.pauseUpdates) this.stateMachine.setState('flip')
    }

    private justJumped = false
    private jumpOnEnter() {
        
        this.pauseUpdates = true
        this.sprite.play('jump', true)
        this.justJumped = true
    }

    private jumpOnUpdate(dt) {
        if(this.justJumped) {
            this.justJumped = false
            this.sprite.setVelocityY(-15* this.getFpsAdjustmet(dt))
        }
        if(!this.pauseUpdates) this.stateMachine.setState('flip')
    }

    private fallOnEnter() {
        this.sprite.setVelocityY(10)
        this.sprite.play('fall')
    }

    private fallOnUpdate(dt) {
        this.adjustVelocityX(dt);
    }

    private landOnEnter() {
        this.pauseUpdates = true
        this.sprite.play('land', true)
    }

    private landOnUpdate() {
        if(this.cursors.left.isDown || this.cursors.right.isDown  || !this.pauseUpdates) this.stateMachine.setState('idle1')
    }

    private flipOnEnter() {
        this.sprite.play('flip', true)
    }

    private flipOnUpdate(dt) {
        this.adjustVelocityX(dt);
        if (this.cursors.down .isDown)
        {
            this.stateMachine.setState('fall')
        }
    }

    private createAnimations() {
        // run animation
        this.sprite.anims.create({
          key: 'run',
          frameRate: 15,
          frames: this.sprite.anims.generateFrameNames('santa', {start: 1, end: 12, prefix: 'run', suffix: '.png'}),
          repeat: -1
         })
        // idle1 (stand, cross arms)
        this.sprite.anims.create({
          key: 'idle1',
          frameRate: .3,
          frames: this.sprite.anims.generateFrameNames('santa', { start: 1, end: 3, prefix: 'idle', suffix: '.png'}),
         })
        // idle2 (hands on hips)
        this.sprite.anims.create({
          key: 'idle2',
          frameRate: .3,
          frames: [{ key: 'santa', frame: 'idle4.png'}],
         })
         // idle3 (hands on hips, toe tapping)
        this.sprite.anims.create({
          key: 'idle3',
          frameRate: 6,
          frames: this.sprite.anims.generateFrameNames('santa', { start: 4, end: 5, prefix: 'idle', suffix: '.png'}),
          repeat: 3
         })
         // idle4 (stand)
        this.sprite.anims.create({
            key: 'idle4',
            frameRate: 1,
            frames: this.sprite.anims.generateFrameNames('santa', { start: 1, end: 1, prefix: 'idle', suffix: '.png'}),
           })
         // takeoff
         this.sprite.anims.create({
          key: 'jump',
          frameRate: 11 ,
          frames: [{ key: 'santa', frame: 'jump1.png'}],
         })
         // flipping
         this.sprite.anims.create({
          key: 'flip',  
          frameRate: 12,
          frames: this.sprite.anims.generateFrameNames('santa', { start: 2, end: 6, prefix: 'jump', suffix: '.png'}),
          repeat: -1
         })
         // landing
         this.sprite.anims.create({
          key: 'land',
          frameRate: 20,
          frames: this.sprite.anims.generateFrameNames('santa', { start: 7, end: 9, prefix: 'jump', suffix: '.png'}),
         })
         // falling
         this.sprite.anims.create({
          key: 'fall',
          frameRate: 20,
          frames: this.sprite.anims.generateFrameNames('santa', { start: 10, end: 11, prefix: 'jump', suffix: '.png'}),
          repeat: -1
         })
         // falling
         this.sprite.anims.create({
            key: 'fall2',
            frameRate: 20,
            frames: this.sprite.anims.generateFrameNames('santa', { start: 10, end: 11, prefix: 'jump', suffix: '.png'}),
            repeat: 18
           })
         // crouching
         this.sprite.anims.create({
          key: 'crouch1',
          frameRate: 12,
          frames: this.sprite.anims.generateFrameNames('santa', { start: 1, end: 2, prefix: 'crouch', suffix: '.png'}),
         })
         // crouching
         this.sprite.anims.create({
          key: 'crouch2',
          frameRate: .2,
          frames: this.sprite.anims.generateFrameNames('santa', { start: 2, end: 3, prefix: 'crouch', suffix: '.png'}),
         })
    
      }

}
