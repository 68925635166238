{"frames": {

"snowman1.png":
{
	"frame": {"x":54,"y":1,"w":63,"h":112},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":63,"h":112},
	"sourceSize": {"w":65,"h":129}
},
"snowman2.png":
{
	"frame": {"x":120,"y":113,"w":56,"h":114},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":1,"w":56,"h":114},
	"sourceSize": {"w":65,"h":129}
},
"snowman3.png":
{
	"frame": {"x":1,"y":1,"w":51,"h":124},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":1,"w":51,"h":124},
	"sourceSize": {"w":65,"h":129}
},
"snowman4.png":
{
	"frame": {"x":1,"y":127,"w":57,"h":121},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":8,"w":57,"h":121},
	"sourceSize": {"w":65,"h":129}
},
"snowman5.png":
{
	"frame": {"x":119,"y":1,"w":57,"h":110},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":19,"w":57,"h":110},
	"sourceSize": {"w":65,"h":129}
},
"snowman6.png":
{
	"frame": {"x":57,"y":348,"w":58,"h":102},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":27,"w":58,"h":102},
	"sourceSize": {"w":65,"h":129}
},
"snowman7.png":
{
	"frame": {"x":1,"y":344,"w":54,"h":108},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":21,"w":54,"h":108},
	"sourceSize": {"w":65,"h":129}
},
"snowman8.png":
{
	"frame": {"x":117,"y":348,"w":56,"h":103},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":26,"w":56,"h":103},
	"sourceSize": {"w":65,"h":129}
},
"snowman9.png":
{
	"frame": {"x":1,"y":250,"w":61,"h":92},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":37,"w":61,"h":92},
	"sourceSize": {"w":65,"h":129}
},
"snowman10.png":
{
	"frame": {"x":64,"y":229,"w":56,"h":117},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":12,"w":56,"h":117},
	"sourceSize": {"w":65,"h":129}
},
"snowman11.png":
{
	"frame": {"x":122,"y":229,"w":54,"h":114},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":9,"w":54,"h":114},
	"sourceSize": {"w":65,"h":129}
},
"snowman12.png":
{
	"frame": {"x":60,"y":115,"w":58,"h":112},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":5,"w":58,"h":112},
	"sourceSize": {"w":65,"h":129}
}},
"meta": {
	"app": "https://www.codeandweb.com/texturepacker",
	"version": "1.0",
	"image": "snowman.png",
	"format": "RGBA8888",
	"size": {"w":177,"h":453},
	"scale": "1",
	"smartupdate": "$TexturePacker:SmartUpdate:27b578834ae34f0dcaf8f30fadf00c5e:117d6ca0dfb8b378727241be0f1a4797:97365287be0f39d292457a96b7527d3c$"
}
}
