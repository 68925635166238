import StateMachine from "../statemachine/StateMachine"
import { sharedInstance as events } from "../scenes/EventCenter"
import ObstaclesController from "../scenes/ObstaclesController"
import { threadId } from "worker_threads"

export default class KrampusController {
    private standSprite: Phaser.Physics.Matter.Sprite
    private jumpSprite: Phaser.Physics.Matter.Sprite
    private obstacles: ObstaclesController
    private scene: Phaser.Scene
    private stateMachine: StateMachine
    private fires: Array<Phaser.Physics.Matter.Sprite> = []
    private health = 3;
    private waitTimer = 0;
    private breathCount = -1;
    private gameOver = false;
    private flip = false;
    private battleJustStarted = true;
    private fpsOffset = 1000/60
   
     constructor(scene: Phaser.Scene, standSprite: Phaser.Physics.Matter.Sprite, jumpSprite: Phaser.Physics.Matter.Sprite, obstacles: ObstaclesController) {
        this.standSprite = standSprite
        this.jumpSprite = jumpSprite
        this.obstacles = obstacles
        this.scene = scene
        this.stateMachine = new StateMachine(this, 'krampus')

        const aFire1 = this.scene.matter.add.sprite(this.standSprite.x, this.standSprite.y, 'fire', '', {
            isSensor:true,
            isStatic:true
        }).setVisible(false).setScale(1.25)
        this.fires.push(aFire1)
        this.obstacles.add('fire', aFire1.body as MatterJS.BodyType)

        const aFire2 = this.scene.matter.add.sprite(this.standSprite.x, this.standSprite.y, 'fire', '', {
            isSensor:true,
            isStatic:true
        }).setVisible(false).setScale(1.25)
        this.fires.push(aFire2)
        this.obstacles.add('fire', aFire2.body as MatterJS.BodyType)

        const aFire3 = this.scene.matter.add.sprite(this.standSprite.x, this.standSprite.y, 'fire', '', {
            isSensor:true,
            isStatic:true
        }).setVisible(false).setScale(1.25)
        this.fires.push(aFire3)
        this.obstacles.add('fire', aFire3.body as MatterJS.BodyType)

        this.stateMachine.addState('breathPause', {
            onEnter: this.breathPauseOnEnter,
            onUpdate: this.breathPauseOnUpdate
        })
        this.stateMachine.addState('jumpPause', {
            onEnter: this.jumpPauseOnEnter,
            onUpdate: this.jumpPauseOnUpdate
        })
        this.stateMachine.addState('jump', {
            onEnter: this.jumpOnEnter,
            onUpdate: this.jumpOnUpdate
        })
        this.stateMachine.addState('fall', {
            onEnter: this.fallOnEnter,
            onUpdate: this.fallOnUpdate
        })
        this.stateMachine.addState('land', {
            onEnter: this.landOnEnter,
            onUpdate: this.landOnUpdate
        })
        this.stateMachine.addState('landed', {
            onEnter: this.landedOnEnter
        })
        this.stateMachine.addState('prebreath', {
            onEnter: this.prebreathOnEnter,
            onUpdate: this.prebreathOnUpdate
        })
        this.stateMachine.addState('breath', {
            onEnter: this.breathOnEnter,
        })
        this.stateMachine.addState('hit', {
            onEnter: this.hitOnEnter,
        })

        events.on('boss-fight', () => { this.stateMachine.setState('breathPause')}, this)
        events.once(Phaser.Scenes.Events.DESTROY, () => {
            events.off('boss-fight', () => this.stateMachine.setState('breathPause'), this)
        })

        events.on('kill-krampus', () => {
            if(!this.standSprite.getData('hitPause')) this.stateMachine.setState('hit')}, this)
        events.once(Phaser.Scenes.Events.DESTROY, () => {
            events.off('kill-krampus', () => {
                if(!this.standSprite.getData('hitPause')) this.stateMachine.setState('hit')
            }, this)
        })
        this.loadAnimation();
        this.standSprite.play('idle')
    }

    init() {
        this.gameOver = false;
        this.health = 3;
        this.waitTimer = 0
        this.breathCount = -1
        this.battleJustStarted = true;
    }

    destroy() {
        events.off('kill-krampus', () => {
            this.stateMachine.setState('hit')
        }, this)
    }

    update(dt: number) {
        this.stateMachine.update(dt)
    }

    private getFpsAdjustmet(dt: number) {
        return dt/this.fpsOffset
    }

    private hitOnEnter() {
        if(this.standSprite.visible) {
            this.standSprite.play('idle')
        this.health--
        if(this.health <= 0) {
            var mySound = this.scene.sound.get('bossTheme')
            this.scene.tweens.add({
                targets:  mySound,
                volume:   0,
                duration: 2500,
                onComplete: () => {
                    this.scene.sound.play('theme')
                }
            });
            events.emit('winner-winner')
            this.standSprite.destroy()
            return
        }

        this.standSprite.setData('hitPause', true)
        const startColor = Phaser.Display.Color.ValueToColor(0xffffff)
        const endColor = Phaser.Display.Color.ValueToColor(0xff0000)
        this.scene.tweens.addCounter({
            from: 0,
            to: 100,
            duration: 100,
            repeat: 10,
            yoyo: true,
            ease: Phaser.Math.Easing.Sine.InOut,
            onUpdate: tween => {
                const value = tween.getValue()
                const colorObject = Phaser.Display.Color.Interpolate.ColorWithColor(
                    startColor,
                    endColor,
                    100,
                    value
                )
                const color = Phaser.Display.Color.GetColor(
                    colorObject.r,
                    colorObject.g,
                    colorObject.b
                )
                this.standSprite?.setTint(color)
            },
            onComplete: () => {
                this.standSprite?.setData('hitPause', false)
                this.breathCount = 0;
                this.stateMachine.setState('prebreath')
             }
        }) 
        }
    }

    private justJumped = false
    private jumpOnEnter() {
        this.waitTimer = 0
        this.standSprite.visible = false 
        this.standSprite.setStatic(false)
        this.jumpSprite.setX(this.standSprite.x)
        this.jumpSprite.setY(this.standSprite.y - 100)
        this.jumpSprite.visible = true
        this.jumpSprite.setFixedRotation()
        this.standSprite.setSensor(true)
        this.jumpSprite.play('jump')
        
        this.justJumped = true
    }

    private jumpOnUpdate(dt: number) {
        this.waitTimer += dt
        if (this.waitTimer > 1000) {
            this.stateMachine.setState('fall')
        } else {
            if(this.justJumped) {
                this.justJumped = false
                this.jumpSprite.setVelocityY(-23 * this.getFpsAdjustmet(dt))
            }
            if(this.flip) {
                this.jumpSprite.setVelocityX(8.5 * this.getFpsAdjustmet(dt))
            } else {
                this.jumpSprite.setVelocityX(-8.55 * this.getFpsAdjustmet(dt))
            }
        }
    }

    private fallOnEnter() {
        this.waitTimer = 0
        this.flip = !this.flip
        this.jumpSprite.setFlipX(this.flip)
    }

    private fallOnUpdate(dt: number) {
        this.waitTimer += dt
        if(this.waitTimer > 600) {
            this.stateMachine.setState('land')
        }
    }

    private landOnEnter() {
        this.waitTimer = 0
        this.jumpSprite.play('land')
    }

    private landOnUpdate(dt: number) {
        this.waitTimer += dt
        if(this.waitTimer > 650) {

            this.jumpSprite.visible = false
            this.standSprite.setStatic(true)
            this.standSprite.setSensor(false)
            this.standSprite.setVelocityX(0)
            this.standSprite.setVelocityY(0)
            this.standSprite.setX(this.jumpSprite.x)
            this.standSprite.setY(1460)
            this.standSprite.setFlipX(this.flip)
            this.standSprite.setRotation(0)
            this.standSprite.visible = true
            this.standSprite.setFixedRotation()
            this.stateMachine.setState('landed')
        }
    }

    private landedOnEnter() {
        this.waitTimer =0
        this.standSprite.play('landed')
        this.stateMachine.setState('breathPause')
    }

    private breathPauseOnEnter() {
        this.waitTimer = 0
    }

    private breathPauseOnUpdate(dt: number) {
        this.waitTimer += dt
        const waitDur = this.battleJustStarted ? 5000 : 2000
        this.battleJustStarted = false
        if(this.waitTimer > 2000) this.stateMachine.setState('prebreath')
    }

    private jumpPauseOnEnter() {
        this.waitTimer = 0
    }

    private jumpPauseOnUpdate(dt: number) {
        this.waitTimer += dt
        if(this.waitTimer > 1000) this.stateMachine.setState('jump')
    }

    private prebreathOnEnter() {
        this.waitTimer = 0
        this.breathCount++
        if(this.breathCount > 2) {
            this.stateMachine.setState('jumpPause')
            this.breathCount = -1
        } else {
            this.standSprite.play('fireBreathing')
        }
    }

    private prebreathOnUpdate(dt: number) {
        this.waitTimer += dt
        if(this.waitTimer > 500) this.stateMachine.setState('breath')
    }

    private breathOnEnter() {
        const dx = this.flip ? 1500 : -1500
        const xOffset = this.flip ? 115 : -115   
        this.fires[this.breathCount].visible = true
        this.fires[this.breathCount].play('eternalFlame')
        this.fires[this.breathCount].setFlipX(this.flip).setScale(1.25)
        this.fires[this.breathCount].setPosition(this.standSprite.x+xOffset, this.standSprite.y-10)
        this.scene.tweens.add({
            targets:  this.fires[this.breathCount],
            x:   this.standSprite.x+dx,
            duration: 2250,
        });
        this.stateMachine.setState('breathPause')
    }

    private loadAnimation() {
        this.standSprite.anims.create({
            key: 'idle',
            frameRate: 1,
            frames: this.standSprite.anims.generateFrameNames('kStand', { start: 3, end: 3, prefix: 'kstand', suffix: '.png'}),
        })
        this.standSprite.anims.create({
            key: 'landed',
            frameRate: 12,
            frames: this.standSprite.anims.generateFrameNames('kStand', { start: 1, end: 3, prefix: 'kstand', suffix: '.png'}),
        })
        this.standSprite.anims.create({
            key: 'fireBreathing',
            frameRate: 12,
            frames: this.standSprite.anims.generateFrameNames('kStand', { start: 4, end: 11, prefix: 'kstand', suffix: '.png'}),
        })
        this.jumpSprite.anims.create({
            key: 'jump',
            frameRate: 12,
            frames: this.jumpSprite.anims.generateFrameNames('kJump', { start: 1, end: 7, prefix: 'kJump', suffix: '.png'}),
        })
        this.jumpSprite.anims.create({
            key: 'land',
            frameRate: 12,
            frames: this.jumpSprite.anims.generateFrameNames('kJump', { start: 5, end: 11, prefix: 'kJump', suffix: '.png'}),
        })
        this.fires[0].anims.create({
            key: 'eternalFlame',
            frameRate: 12,
            frames: this.fires[0].anims.generateFrameNames('fire', { start: 1, end: 10, prefix: 'fire', suffix: '.png'}),
            repeat: -1
        })
        this.fires[1].anims.create({
            key: 'eternalFlame',
            frameRate: 12,
            frames: this.fires[1].anims.generateFrameNames('fire', { start: 1, end: 10, prefix: 'fire', suffix: '.png'}),
            repeat: -1
        })
        this.fires[2].anims.create({
            key: 'eternalFlame',
            frameRate: 12,
            frames: this.fires[2].anims.generateFrameNames('fire', { start: 1, end: 10, prefix: 'fire', suffix: '.png'}),
            repeat: -1
        })
    }

}