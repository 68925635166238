{"frames": {

"lukeBlue1.png":
{
	"frame": {"x":67,"y":1,"w":63,"h":74},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":74},
	"sourceSize": {"w":64,"h":74}
},
"lukeBlue2.png":
{
	"frame": {"x":1,"y":1,"w":64,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":74},
	"sourceSize": {"w":64,"h":74}
},
"lukeBlue3.png":
{
	"frame": {"x":1,"y":77,"w":64,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":74},
	"sourceSize": {"w":64,"h":74}
},
"lukeBlue4.png":
{
	"frame": {"x":67,"y":77,"w":63,"h":74},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":74},
	"sourceSize": {"w":64,"h":74}
},
"lukeBlue5.png":
{
	"frame": {"x":67,"y":153,"w":63,"h":74},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":74},
	"sourceSize": {"w":64,"h":74}
},
"lukeBlue6.png":
{
	"frame": {"x":67,"y":229,"w":63,"h":74},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":74},
	"sourceSize": {"w":64,"h":74}
},
"lukeBlue7.png":
{
	"frame": {"x":67,"y":305,"w":63,"h":74},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":74},
	"sourceSize": {"w":64,"h":74}
},
"lukeBlue8.png":
{
	"frame": {"x":67,"y":381,"w":63,"h":74},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":74},
	"sourceSize": {"w":64,"h":74}
},
"lukeBlue9.png":
{
	"frame": {"x":132,"y":1,"w":63,"h":74},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":74},
	"sourceSize": {"w":64,"h":74}
},
"lukeBlue10.png":
{
	"frame": {"x":67,"y":1,"w":63,"h":74},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":74},
	"sourceSize": {"w":64,"h":74}
},
"lukeGreen2.png":
{
	"frame": {"x":1,"y":153,"w":64,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":74},
	"sourceSize": {"w":64,"h":74}
},
"lukeGreen3.png":
{
	"frame": {"x":1,"y":229,"w":64,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":74},
	"sourceSize": {"w":64,"h":74}
},
"lukeGreen4.png":
{
	"frame": {"x":132,"y":77,"w":63,"h":74},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":74},
	"sourceSize": {"w":64,"h":74}
},
"lukeGreen5.png":
{
	"frame": {"x":132,"y":153,"w":63,"h":74},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":74},
	"sourceSize": {"w":64,"h":74}
},
"lukePurple2.png":
{
	"frame": {"x":1,"y":305,"w":64,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":74},
	"sourceSize": {"w":64,"h":74}
},
"lukePurple3.png":
{
	"frame": {"x":1,"y":381,"w":64,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":74},
	"sourceSize": {"w":64,"h":74}
},
"lukePurple4.png":
{
	"frame": {"x":132,"y":229,"w":63,"h":74},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":74},
	"sourceSize": {"w":64,"h":74}
},
"lukePurple5.png":
{
	"frame": {"x":132,"y":305,"w":63,"h":74},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":74},
	"sourceSize": {"w":64,"h":74}
}},
"meta": {
	"app": "https://www.codeandweb.com/texturepacker",
	"version": "1.0",
	"image": "luke.png",
	"format": "RGBA8888",
	"size": {"w":196,"h":456},
	"scale": "1",
	"smartupdate": "$TexturePacker:SmartUpdate:8259fb405aa5961d7aed7ceae011220c:da1088e3ab244d5845afd820c7338a9c:51d8fa408ed5251be8bb8274bf54878d$"
}
}
