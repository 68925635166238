{"frames": {

"present0.png":
{
	"frame": {"x":1,"y":1,"w":74,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":74,"h":74},
	"sourceSize": {"w":74,"h":74}
},
"present1.png":
{
	"frame": {"x":77,"y":1,"w":74,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":74,"h":74},
	"sourceSize": {"w":74,"h":74}
},
"present2.png":
{
	"frame": {"x":153,"y":1,"w":74,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":74,"h":74},
	"sourceSize": {"w":74,"h":74}
},
"present3.png":
{
	"frame": {"x":229,"y":1,"w":74,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":74,"h":74},
	"sourceSize": {"w":74,"h":74}
},
"present4.png":
{
	"frame": {"x":305,"y":1,"w":74,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":74,"h":74},
	"sourceSize": {"w":74,"h":74}
}},
"meta": {
	"app": "https://www.codeandweb.com/texturepacker",
	"version": "1.0",
	"image": "presentMeter.png",
	"format": "RGBA8888",
	"size": {"w":380,"h":76},
	"scale": "1",
	"smartupdate": "$TexturePacker:SmartUpdate:4f3ad9465704d3d07a10daa12c0ac3b9:d2fc8aa3d0e8beadf798edd97ddc67aa:61db4bf90299b61eeeb96e2960f5f6c5$"
}
}
