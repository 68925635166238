import StateMachine from "../statemachine/StateMachine"
import { sharedInstance as events } from "../scenes/EventCenter"

export default class PacingEnemyController {
    private sprite: Phaser.Physics.Matter.Sprite
    private scene: Phaser.Scene
    private stateMachine: StateMachine
    private spawnX = 0;
    private enemyType = ''
    private initialDirection = ''
    private paceLength = 0
    private velocity = 0
    private fpsOffset = 1000/60

    constructor(scene: Phaser.Scene, sprite: Phaser.Physics.Matter.Sprite, enemyType: string, initialDirection: string, paceLength: number, velocity: number){
        this.sprite = sprite
        this.scene = scene
        this.stateMachine = new StateMachine(this, enemyType)
        this.spawnX = this.sprite.x
        this.enemyType = enemyType
        this.initialDirection = initialDirection
        this.paceLength = paceLength
        this.velocity = velocity

        if (this.paceLength <= sprite.width) {
            throw new Error(`Pace length(${this.paceLength}) must be greater than the width of the pacingEnemy(${sprite.width})`)
        }

        this.stateMachine.addState('move-left', {
            onEnter: this.leftOnEnter,
            onUpdate: this.leftOnUpdate
        })
        this.stateMachine.addState('move-right', {
            onEnter: this.rightOnEnter,
            onUpdate: this.rightOnUpdate
        })
        this.stateMachine.addState('die', {
            onUpdate: this.dieOnEnter
        })
        this.loadAnimation();

        this.sprite.play('bouncing')
        if (this.initialDirection === 'left') {
            this.stateMachine.setState('move-left')
        } else {
            this.stateMachine.setState('move-right') // right if 'left' was not provided 
        }
        events.on(`kill-${this.enemyType}`, this.handleDieEvent, this)
    }

    private getFpsAdjustmet(dt: number) {
        return dt/this.fpsOffset
    }

    private handleDieEvent(aSprite: Phaser.Physics.Matter.Sprite) {
        if(this.sprite !== aSprite) {
            return
        }
        events.off(`kill-${this.enemyType}`, this.handleDieEvent, this)
        this.sprite.setVelocityX(0)
        this.stateMachine.setState('die')
    }

    destroy() {
        events.off(`kill-${this.enemyType}`, this.handleDieEvent, this)
    }

    update(dt: number) {
        this.stateMachine.update(dt)
    }

    private leftOnEnter() {
        this.sprite.flipX = false
    }

    private leftOnUpdate(dt: number) {
        this.sprite.setVelocityX(-1 * this.velocity * this.getFpsAdjustmet(dt))
        const diff = Phaser.Math.Difference(this.sprite.x, this.spawnX)
        if ((this.sprite.x < this.spawnX) && diff >= this.paceLength/2) {
            this.stateMachine.setState('move-right')
        }
    }

    private rightOnEnter() {
        this.sprite.flipX = true
    }

    private rightOnUpdate(dt: number) {
        this.sprite.setVelocityX(this.velocity)
        const diff = Phaser.Math.Difference(this.sprite.x, this.spawnX)
        if ((this.sprite.x > this.spawnX) && diff >= this.paceLength/2) {
            this.stateMachine.setState('move-left')
        }
    }

    private dieOnEnter() {
        this.sprite.destroy()
    }

    private loadAnimation() {
        this.sprite.anims.create({
            key: 'bouncing',
            frameRate: 12,
            frames: this.sprite.anims.generateFrameNames(this.enemyType, { start: 1, end: 12, prefix: this.enemyType, suffix: '.png'}),
            repeat: -1
           })
    }

}