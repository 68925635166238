import StateMachine from "../statemachine/StateMachine"
import { sharedInstance as events } from "../scenes/EventCenter"

export default class ElfController {
    private elfSprite: Phaser.Physics.Matter.Sprite
    private ornamentSprite: Phaser.Physics.Matter.Sprite
    private scene: Phaser.Scene
    private elfStateMachine: StateMachine
    private ornamentStateMachine: StateMachine
    private colors = ['Blue', 'Green', 'Purple']
    private currentColor = ''
    private pauseTimer = 0


    constructor(scene: Phaser.Scene, elfSprite: Phaser.Physics.Matter.Sprite, ornamentSprite: Phaser.Physics.Matter.Sprite){
        
        this.elfSprite = elfSprite
        this.ornamentSprite = ornamentSprite
        this.scene = scene
        this.elfStateMachine = new StateMachine(this, 'elf')
        this.ornamentStateMachine = new StateMachine(this, 'ornament')

        this.ornamentStateMachine.addState('reset-ornament', {
            onEnter: this.resetOrnamanetOnEnter
        })
        this.ornamentStateMachine.addState('fall', {
            onEnter: this.fallOnEnter,
            onUpdate: this.fallOnUpdate
        })
        this.ornamentStateMachine.addState('break', {
            onEnter: this.breakOnEnter,
        })
        this.elfStateMachine.addState('lift', {
            onEnter: this.liftOnEnter,
            onUpdate: this.liftOnUpdate
        })
        this.elfStateMachine.addState('drop', {
            onEnter: this.dropOnEnter,
            onUpdate: this.dropOnUpdate
        })
        this.elfStateMachine.addState('reset-elf', {
            onEnter: this.resetElfOnEnter,
            onUpdate: this.resetElfOnUpdate
        })
        this.loadAnimation();
        this.elfSprite.play('start')
        this.ornamentStateMachine.setState('reset-ornament')
        events.on('kill-elf', this.handleDieEvent, this)
    }

    private handleDieEvent(aSprite: Phaser.Physics.Matter.Sprite) {
        if(this.elfSprite !== aSprite) {
            return
        }
        events.off('kill-elf', this.handleDieEvent, this)
        this.elfStateMachine.setState('die')
    }

    ornamentColision() {
        console.log('hello, from the elf controller')
    }

    destroy() {
        events.off('kill-elf', this.handleDieEvent, this)
    }

    update(dt: number) {
        this.elfStateMachine.update(dt)
        this.ornamentStateMachine.update(dt)
    }
    private liftOnEnter() {
        this.pauseTimer = 0
        this.elfSprite.play(`lift${this.currentColor}`)
    }

    private liftOnUpdate(dt: number) {
        this.pauseTimer += dt
        if(this.pauseTimer >= 500) {
            this.elfStateMachine.setState('drop')
            this.ornamentStateMachine.setState('fall')
        }
    }

    private dropOnEnter() {
        this.pauseTimer = 0
        this.elfSprite.play('drop')
    }

    private dropOnUpdate(dt: number) {
        this.pauseTimer += dt
        if(this.pauseTimer >= 1000) {
            this.elfStateMachine.setState('reset-elf')
        }
    }

    private resetElfOnEnter() {
        this.pauseTimer = 0
        this.elfSprite.play('reset')
    }

    private resetElfOnUpdate(dt: number) {
        this.pauseTimer += dt
        const randWiatTime = Phaser.Math.Between(500,3000)
        if(this.pauseTimer >= randWiatTime) {
            this.ornamentStateMachine.setState('reset-ornament')
        }
    }

    private resetOrnamanetOnEnter() {
        this.ornamentSprite.setVisible(false)
        const rand = Phaser.Math.Between(0,2)
        this.currentColor = this.colors[rand]
        this.ornamentSprite.setPosition(this.elfSprite.x - 22,this.elfSprite.y + 3)
        this.ornamentSprite.play(`whole${this.currentColor}`)
        this.ornamentSprite.setData("broken", false)
        this.elfStateMachine.setState('lift')
    }

    private fallOnEnter() {
        const breakAnimated = false;
        this.ornamentSprite.setVisible(true)
        this.scene.tweens.addCounter({
            from: this.ornamentSprite.y,
            to: 1550,
            duration: 1000,
            ease: Phaser.Math.Easing.Cubic.In,
            onUpdate: tween => {
                this.ornamentSprite.setY(tween.getValue())
                if(!breakAnimated && this.ornamentSprite.getData("broken")) {
                    // broke by santa hit
                    this.ornamentStateMachine.setState('break')
                }
            },
            onComplete: () => {
                if(!breakAnimated) {
                    this.ornamentStateMachine.setState('break')
                }
            }
          })

    }

    private fallOnUpdate() {
        
    }

    private breakOnEnter() {
        this.ornamentSprite.setData("broken", true)
        this.ornamentSprite.play(`break${this.currentColor}`)
    }

    private loadAnimation() {
        this.elfSprite.anims.create({
            key: 'start',
            frameRate: 1,
            frames: this.elfSprite.anims.generateFrameNames('elf', { start: 1, end: 1, prefix: 'lukeBlue', suffix: '.png'}),
        })
        this.elfSprite.anims.create({
            key: 'drop',
            frameRate:  1,
            frames: this.elfSprite.anims.generateFrameNames('elf', { start: 6, end: 6, prefix: 'lukeBlue', suffix: '.png'}),
        })
        this.elfSprite.anims.create({
            key: 'reset',
            frameRate: 12,
            frames: this.elfSprite.anims.generateFrameNames('elf', { start: 7, end: 10, prefix: 'lukeBlue', suffix: '.png'}),
        })
        this.colors.forEach(color =>{
            this.elfSprite.anims.create({
                key: `lift${color}`,
                frameRate: 12,
                frames: this.elfSprite.anims.generateFrameNames('elf', { start: 2, end: 5, prefix: `luke${color}`, suffix: '.png'}),
            })
            this.ornamentSprite.anims.create({
                key: `whole${color}`,
                frameRate: 1,
                frames: this.ornamentSprite.anims.generateFrameNames('ornament', { start: 1, end: 1, prefix: `ornament${color}`, suffix: '.png'}),
            })
            this.ornamentSprite.anims.create({
                key: `break${color}`,
                frameRate: 12,
                frames: this.ornamentSprite.anims.generateFrameNames('ornament', { start: 2, end: 4, prefix: `ornament${color}`, suffix: '.png'}),
            })
        })
    }
}