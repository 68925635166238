{"frames": {

"kstand1.png":
{
	"frame": {"x":1,"y":266,"w":138,"h":125},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":20,"y":27,"w":138,"h":125},
	"sourceSize": {"w":158,"h":152}
},
"kstand2.png":
{
	"frame": {"x":1,"y":798,"w":135,"h":140},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":19,"y":12,"w":135,"h":140},
	"sourceSize": {"w":158,"h":152}
},
"kstand3.png":
{
	"frame": {"x":1,"y":1501,"w":127,"h":151},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":20,"y":1,"w":127,"h":151},
	"sourceSize": {"w":158,"h":152}
},
"kstand4.png":
{
	"frame": {"x":1,"y":1215,"w":129,"h":146},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":18,"y":6,"w":129,"h":146},
	"sourceSize": {"w":158,"h":152}
},
"kstand5.png":
{
	"frame": {"x":1,"y":940,"w":132,"h":138},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":15,"y":14,"w":132,"h":138},
	"sourceSize": {"w":158,"h":152}
},
"kstand6.png":
{
	"frame": {"x":1,"y":535,"w":136,"h":131},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":11,"y":21,"w":136,"h":131},
	"sourceSize": {"w":158,"h":152}
},
"kstand7.png":
{
	"frame": {"x":1,"y":132,"w":140,"h":132},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":20,"w":140,"h":132},
	"sourceSize": {"w":158,"h":152}
},
"kstand8.png":
{
	"frame": {"x":1,"y":1,"w":145,"h":129},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":23,"w":145,"h":129},
	"sourceSize": {"w":158,"h":152}
},
"kstand9.png":
{
	"frame": {"x":1,"y":668,"w":136,"h":128},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":12,"y":24,"w":136,"h":128},
	"sourceSize": {"w":158,"h":152}
},
"kstand10.png":
{
	"frame": {"x":1,"y":1080,"w":131,"h":133},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":16,"y":19,"w":131,"h":133},
	"sourceSize": {"w":158,"h":152}
},
"kstand11.png":
{
	"frame": {"x":1,"y":1654,"w":127,"h":142},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":20,"y":10,"w":127,"h":142},
	"sourceSize": {"w":158,"h":152}
},
"kstand12.png":
{
	"frame": {"x":1,"y":393,"w":136,"h":140},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":18,"y":12,"w":136,"h":140},
	"sourceSize": {"w":158,"h":152}
},
"kstand13.png":
{
	"frame": {"x":1,"y":1363,"w":128,"h":136},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":14,"y":16,"w":128,"h":136},
	"sourceSize": {"w":158,"h":152}
}},
"meta": {
	"app": "https://www.codeandweb.com/texturepacker",
	"version": "1.0",
	"image": "kStand.png",
	"format": "RGBA8888",
	"size": {"w":147,"h":1797},
	"scale": "1",
	"smartupdate": "$TexturePacker:SmartUpdate:318eae50778818bffd126356176e711e:2f7d4dc5d73b300cff0ac8c2a32c3d20:88be035c0ad2bae9ad9df4f528a428ab$"
}
}
