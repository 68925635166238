import { secureHeapUsed } from 'crypto'
import Phaser from 'phaser'
import PacingEnemyController from '../controls/PacingEnemyController'
import PlayerController from '../controls/PlayerController'
import KrampusController from '../controls/KrampusController'
import ElfController from '../controls/ElfController'
const trees = require('../../public/assets/trees.png')
const clouds = require("../../public/assets/clouds.png")
const mountains = require("../../public/assets/peaks.png")
const sunset = require("../../public/assets/sunset.png")
const pixelFont = require("../../public/assets/bitmapFonts/pixelFont.png")
const pixelFontXml = require("url:../../public/assets/bitmapFonts/pixelFont.xml")
const present = require("../../public/assets/present.png")
const presentClosed = require("../../public/assets/presentClosed.png")
const santa = require("../../public/assets/santa.png")
const santaJson = require("../../public/assets/santa.json")
const flakes = require("../../public/assets/flakeSky.png")
const ginger = require("../../public/assets/ginger.png")
const gingerJson = require("../../public/assets/ginger.json")
const elf = require("../../public/assets/luke.png")
const elfJson = require("../../public/assets/luke.json")
const kStand = require("../../public/assets/krampStand.png")
const kStandJson = require("../../public/assets/krampStand.json")
const fire = require("../../public/assets/fire.png")
const fireJson = require("../../public/assets/fire.json")
const kJump = require("../../public/assets/kJump.png")
const kJumpJson = require("../../public/assets/kJump.json")
const ornament = require("../../public/assets/ornament.png")
const ornamentJson = require("../../public/assets/ornament.json")
const bubble = require("../../public/assets/bubble.png")
const snowman = require("../../public/assets/snowman.png")
const snowmanJson = require("../../public/assets/snowman.json")
const presents = require("../../public/assets/present.png")
const presentsJson = require("../../public/assets/present.json")
const sheet = require("../../public/assets/platform_sheet.png")
const sheetJson = require("../../public/assets/coolWorld.json")
import { sharedInstance as events } from "../scenes/EventCenter"
import ObstaclesController from './ObstaclesController'
// @ts-ignore
import mariah from 'url:../../public/assets/sounds/themes/mariah.mp3'
// @ts-ignore
import bandaid from 'url:../../public/assets/sounds/themes/bandaid.mp3'
// @ts-ignore
import wham from 'url:../../public/assets/sounds/themes/wham.mp3'
// @ts-ignore
import carol from 'url:../../public/assets/sounds/themes/carol.mp3'
// @ts-ignore
import presentChime from 'url:../../public/assets/sounds/fx/present.wav'
// @ts-ignore
import enemyHit from 'url:../../public/assets/sounds/fx/enemyHit.wav'
import { kill } from 'process'
import { threadId } from 'worker_threads'

const createAligned = (scene: Phaser.Scene, yLocation: number, count: number, texture: string, scrollFactor: number, scale: number = 1, xOffset = 0) => {
  let xLocation = 0
  for(let i = 0; i < count; i++) {
    const img = scene.add.image(xLocation + xOffset,yLocation, texture).setOrigin(0,0).setScrollFactor(scrollFactor, 1).setScale(scale)
    xLocation += img.width*scale;
  }
}

const createAlignedFlakes = (scene: Phaser.Scene, yLocation: number, count: number, texture: string, scrollFactor: number) => {
  let xLocation = 0
  let imgs: Array<Phaser.GameObjects.Image> = []
  for(let i = 0; i < count; i++) {
    const img = scene.add.image(xLocation,yLocation, texture).setOrigin(0,0).setScrollFactor(scrollFactor, 1)
    xLocation += img.width
    img.setAlpha(.66)
    imgs.push(img)
  }
  scene.tweens.addCounter({
    from: -700,
    to: 1700,
    duration: 20000,
    repeat: -1,
    onUpdate: tween => {
        imgs.forEach(img => {
          img.setY(tween.getValue())
        })
    }
  })
}

export default class Game extends Phaser.Scene
{
  // initialize to value none-zero value to ignore tilesheet and spawn santa deeper into the level
  private hackSantaSpwanX = 0 // none 
  // private hackSantaSpwanX = 2000 // carebear/ ginger
  // private hackSantaSpwanX = 3780 // house / car / warp
  // private hackSantaSpwanX = 5280 // snowmen / dino
  // private hackSantaSpwanX = 7850 // elves of on a shelf
  // private hackSantaSpwanX = 10540 // pre-bridge
  // private hackSantaSpwanX = 11630 // bridge
  private cursors!: Phaser.Types.Input.Keyboard.CursorKeys
  private santa?: Phaser.Physics.Matter.Sprite
  private playerController?: PlayerController
  private obstacles!: ObstaclesController
  private pacingEnemies: Array<PacingEnemyController> = []
  private elves: Array<ElfController> = []
  private krampus?: KrampusController
  private muted = false

  constructor() {
    super('gameplay')
  }

  private handleKeyUp(event) {
    if (event.keyCode === Phaser.Input.Keyboard.KeyCodes.M){
      console.log('m')
      this.sound.volume = this.sound.volume === 0 ? .6 : 0;
    }
  }

  init(data) {
    this.muted = data.muted
    this.cursors = this.input.keyboard.createCursorKeys()
    this.obstacles = new ObstaclesController()
    this.sound.stopAll()

    this.events.once(Phaser.Scenes.Events.DESTROY, () => {
      this.destroy()
    })
    this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.M)
    this.input.keyboard.on('keyup', this.handleKeyUp, this)

  }

  preload() {
    this.load.bitmapFont('pixelFont', pixelFont, pixelFontXml)
    const rand = Phaser.Math.Between(1,100)
    if (rand <= 33) {
      this.load.audio('theme', mariah)
    } else if (rand <= 66) {
      this.load.audio('theme', wham)
    } else { 
      this.load.audio('theme', bandaid)
    }
    this.load.audio('bossTheme', carol)
    this.load.audio('presentChime', presentChime)
    this.load.audio('enemyHit', enemyHit)
    this.load.atlas('santa', santa, santaJson)
    this.load.atlas('ginger', ginger, gingerJson)
    this.load.atlas('elf', elf, elfJson)
    this.load.image ('bubble', bubble)
    this.load.atlas('kJump', kJump, kJumpJson)
    this.load.atlas('kStand', kStand, kStandJson)
    this.load.atlas('fire', fire, fireJson)
    this.load.atlas('ornament', ornament, ornamentJson)
    this.load.atlas('snowman', snowman, snowmanJson)
    this.load.atlas('presents', presents, presentsJson)
    this.load.image ('sheet', sheet)
    this.load.image ('trees', trees)
    this.load.image ('clouds', clouds)
    this.load.image ('mountains', mountains)
    this.load.image ('flakes', flakes)
    this.load.image ('sunset', sunset)
    this.load.tilemapTiledJSON('sheetJson', sheetJson)
    this.load.image('present', present)
    this.load.image('presentClosed', presentClosed)
    this.load.bitmapFont('pixelFont', pixelFont, pixelFontXml);
  }

  private moreFlakes () {
    createAlignedFlakes(this, -670, 7, 'flakes', .9)
  } 

  create() { 
    // 
    this.matter.set30Hz()
    this.matter.world.autoUpdate = false;
    this.add.bitmapText(10, 1600, 'pixelFont','Merry Christmas!',34);
    this.scene.launch('ui')
    const { width, height } = this.scale 
    const bottomY = 28*60;
    const treeY = bottomY - 370;
    const mountainY = treeY - 370;
    const cloudY = height;

    this.add.image(0, height, 'sunset').setScale(12).setScrollFactor(0, 1);
    createAligned(this, cloudY, 2, 'clouds', .2)
    createAligned(this, mountainY, 3, 'mountains', .5)
    createAligned(this, treeY, 9, 'trees', .75)
    createAlignedFlakes(this, cloudY-500, 7, 'flakes', .9)

    this.time.addEvent({delay: 10000, callbackScope: this, callback: this.moreFlakes})
    

    const map = this.make.tilemap({ key: 'sheetJson' })
    const tileset = map.addTilesetImage('iceworld', 'sheet') // iceworld named in Tiled
    const ground = map.createLayer('ground', tileset)

    this.cameras.main.setBounds(60, 0, (228*60), bottomY);
    ground.setCollisionByProperty({ collides: true })
    this.matter.world.convertTilemapLayer(ground)

    const objectLayer = map.getObjectLayer('objects')
    let snowmanDirectionFlipper = true;
    const gingerPaceyName ={
      ginger1: 200,
      ginger2: 800
    }
    objectLayer.objects.forEach(obj => {
      const { x = 0, y = 0, width = 0, name, type } = obj
      switch(type){
        case 'santaSpawn': {
          this.santa = this.matter.add.sprite(this.hackSantaSpwanX || x, y, 'santa', '', {})
          this.santa.setFixedRotation()
          this.playerController = new PlayerController(this, this.santa, this.cameras.main, this.cursors, this.obstacles)
          this.santa.setData('type', type)
          this.santa.setData('name', name)
          break
        }
        case 'present': {
          const present = this.matter.add.sprite(x, y, 'presentClosed', '', {
            isStatic: true,
             render: {
               sprite:{
                yOffset: .37,
               
              }
            }
          });
          present.setScale(1.25)
          present.setData('type', type)
          present.setData('opened', false)
          present.setData('name', name)
          this.createPresentAnimations(present)
          present.play('closed')
          this.obstacles.add(type, present.body as MatterJS.BodyType)
          break
        }
        case 'ginger': {
          this.generatePacingEnemy(type, name, x, y, true, gingerPaceyName[name], 2, 1)
          break
        }
        case 'elf': {
          this.generateElf(type, name, x, y, true, gingerPaceyName[name], 2)
          break
        }
        case 'snowman': {
          this.generatePacingEnemy(type, name, x, y, snowmanDirectionFlipper, 360, 3, 1.5)
          snowmanDirectionFlipper = !snowmanDirectionFlipper
          break
        }
        case 'carRec1': {
          const car = this.matter.add.rectangle(x+130, y+110, width, height/4+10, {
            isStatic: true,
          })
          this.obstacles.add('car', car)
          break
        }
        case 'carRec2': {
          const car = this.matter.add.rectangle(x+30, y+90, width, height/5, {
            isStatic: true,
          })          
          this.obstacles.add('car', car)
          break
        }
        case 'carPoly': {
          const car = this.matter.add.polygon(x+30,y-50,3,20, {
            isStatic: true,
          })
          const car2 = this.matter.add.rectangle(x+30,y,50,100, {
            isStatic: true,
          })
          this.obstacles.add('car', car)
          this.obstacles.add('car', car2)
          break
        }
        case 'killZone': {
          const killZone = this.matter.add.rectangle(x, y+600, width, height, {
            isSensor: true,
            isStatic: true
          })
          this.obstacles.add(type, killZone)
          break
        }
        case 'krampus': {
          const standingKrampus = this.matter.add.sprite(x, 1468, 'kStand', '', {
            isStatic: true
          })
          standingKrampus.setScale(1.33).setFixedRotation()
          standingKrampus.setData('type', type)
          standingKrampus.setData('name', name)
          const jumpKrampus = this.matter.add.sprite(x, y, 'kJump').setFixedRotation()
          jumpKrampus.setScale(1.33).setFixedRotation()
          jumpKrampus.setVisible(false)
          jumpKrampus.setSensor(true)
          jumpKrampus.setData('type', type + 'Jump')
          jumpKrampus.setData('name', name + 'Jump')
          this.krampus = new KrampusController(this, standingKrampus, jumpKrampus, this.obstacles)
          this.obstacles.add(type, standingKrampus.body as MatterJS.BodyType)
          this.obstacles.add(type+ 'Jump', jumpKrampus.body as MatterJS.BodyType)
          break
        }
      }
    })

    const theme = this.sound.add('theme', {
      loop: true
    });
    theme.play()

    events.on('present-collected', this.animatePresentCollection, this)
    this.events.once(Phaser.Scenes.Events.DESTROY, () => {
      events.off('present-collected', this.animatePresentCollection, this)
    })

    events.on('game-over-man', () => this.gameEnded(false), this)
    this.events.once(Phaser.Scenes.Events.DESTROY, () => {
      events.off('game-over-man', () => this.gameEnded(false), this)
    })

    events.on('winner-winner', () => this.gameEnded(true), this)
    this.events.once(Phaser.Scenes.Events.DESTROY, () => {
        events.off('winner-winner', () => this.gameEnded(true), this)
    })
  }

  private gameEnded(winner: boolean) {
    this.santa?.setVisible(false)
    this.santa?.setStatic(true)
    var myTheme = this.sound.get('theme')
    var myBossTheme = this.sound.get('bossTheme')
    this.tweens.add({
        targets:  [myTheme, myBossTheme],
        volume:   0,
        duration: 500,
        onComplete: () => {
          this.scene.stop('ui')
          this.scene.start('menu', {
            dead: !winner,
            winner: winner
          });
        }
    });
  }

  destroy () {
    this.pacingEnemies.forEach(snowman => {
      snowman.destroy()
    });
    this.sound.stopAll()
  }

  private generatePacingEnemy(type: string, name: string, spawnX: number, spawnY: number, initialDirectionLeft: boolean, paceLength: number, velovity: number, scale: number, yOffset: number = 0) {
    const enemy = this.matter.add.sprite(spawnX, spawnY, type)
    const pacingEnemyController = new PacingEnemyController(this, enemy, type, initialDirectionLeft ? 'left' : 'right', paceLength, velovity)
    enemy.setScale(scale)
    enemy.setFixedRotation()
    enemy.setData('type', type)
    enemy.setData('name', name)
    this.pacingEnemies.push(pacingEnemyController)
    this.obstacles.add(type, enemy.body as MatterJS.BodyType)
  }

  private generateElf(type: string, name: string, spawnX: number, spawnY: number, initialDirectionLeft: boolean, paceLength: number, velovity: number) {
      const elf = this.matter.add.sprite(spawnX, spawnY, type, '', {
        isStatic: true
      });
      const ornament = this.matter.add.sprite(spawnX, spawnY, 'ornament', '', {
        isSensor: true,
        isStatic: true
      })
      const elfController = new ElfController(this, elf, ornament)
    elf.setFixedRotation()
    elf.setData('type', type)
    elf.setData('name', name)
    this.elves.push(elfController)
    this.obstacles.add('elf', elf.body as MatterJS.BodyType)
    this.obstacles.add('ornament', ornament.body as MatterJS.BodyType)
  }

  private animatePresentCollection(sprite) {
    sprite.play('open')
    this.sound.play('presentChime')
  }
  
  private accumulator = 0
  private fixedDelta = 1000/60
  update(t: number, dt: number) {

    this.accumulator += dt;
    while(this.accumulator >= this.fixedDelta) {
      this.accumulator -= this.fixedDelta
      this.matter.world.step(this.fixedDelta);
      this.playerController?.update(this.fixedDelta)
      this.krampus?.update(this.fixedDelta)
    this.pacingEnemies.forEach(pacingEnemy => {
      pacingEnemy.update(this.fixedDelta)
    });
    this.elves.forEach(elf => {
      elf.update(this.fixedDelta)
    });
    }
  }

  private createPresentAnimations(present) {
    present.anims.create({
      key: 'closed',
      frameRate: 1,
      frames: present.anims.generateFrameNames('presents', {start: 1, end: 1, prefix: present.getData('name'), suffix: '.png'}),
     })
    present.anims.create({
      key: 'open',
      frameRate: 10,
      frames: present.anims.generateFrameNames('presents', {start: 2, end: 25, prefix: present.getData('name'), suffix: '.png'}),
     })
  }

}
