import Phaser from "phaser"
import { sharedInstance as events } from "../scenes/EventCenter"
const health = require("../../public/assets/health.png")
const healthJson = require("../../public/assets/health.json")
const present = require("../../public/assets/presentMeter.png")
const presentJson = require("../../public/assets/presentMeter.json")
// @ts-ignore
import santaHit from 'url:../../public/assets/sounds/fx/santaHit.wav'

export default class UI extends Phaser.Scene {
    private presentCount = 0
    private presentMeter!: Phaser.GameObjects.Sprite
    private heathMeter!: Phaser.GameObjects.Sprite
    private hitLock = false;

    constructor() {
        super('ui')
      }
    
      init() {
        this.presentCount = 0
        this.hitLock = false;
      }

      preload() {
        this.load.audio('santaHit', santaHit)
        this.load.atlas('health', health, healthJson)
        this.load.atlas('presentMeter', present, presentJson)
      }

      private handleHit(healthVal: number) {
        this.heathMeter.play(`health${healthVal}`)
        this.sound.play('santaHit')
      }

      private handlePresentCollection() {
        this.presentCount++
        this.presentMeter.play(`present${this.presentCount}`)
        console.log(`present${this.presentCount}`)
      }

      create() {
        this.heathMeter = this.matter.add.sprite(65, 65, 'health', '', {
          isStatic: true,
          isSensor: true
        });
        

        this.presentMeter = this.matter.add.sprite(160, 60, 'presentMeter', '', {
          isStatic: true,
          isSensor: true
        });
        this.presentMeter.setScale(.8)

        this.loadAnimation()
        this.heathMeter.play(`health3`)
        this.presentMeter.play(`present0`)
      

        events.on('present-collected', this.handlePresentCollection, this)
        this.events.once(Phaser.Scenes.Events.DESTROY, () => {
            events.off('present-collected', this.handlePresentCollection, this)
        })

        events.on('health-changed', this.handleHit, this)
        this.events.once(Phaser.Scenes.Events.DESTROY, () => {
            events.off('present-hit', this.handleHit, this)
        })
      }

      private loadAnimation() {
        this.heathMeter.anims.create({
          key: 'health3',
          frameRate: 1,
          frames: this.heathMeter.anims.generateFrameNames('health', { start: 3, end: 3, prefix: 'health', suffix: '.png'}),
        })
        this.heathMeter.anims.create({
          key: 'health2',
          frameRate: 1,
          frames: this.heathMeter.anims.generateFrameNames('health', { start: 2, end: 2, prefix: 'health', suffix: '.png'}),
        })
        this.heathMeter.anims.create({
          key: 'health1',
          frameRate: 1,
          frames: this.heathMeter.anims.generateFrameNames('health', { start: 1, end: 1, prefix: 'health', suffix: '.png'}),
        })
        this.heathMeter.anims.create({
          key: 'health0',
          frameRate: 1,
          frames: this.heathMeter.anims.generateFrameNames('health', { start: 0, end: 0, prefix: 'health', suffix: '.png'}),
        })
        this.presentMeter.anims.create({
          key: 'present4',
          frameRate: 1,
          frames: this.presentMeter.anims.generateFrameNames('presentMeter', { start: 4, end: 4, prefix: 'present', suffix: '.png'}),
        })
        this.presentMeter.anims.create({
          key: 'present3',
          frameRate: 1,
          frames: this.presentMeter.anims.generateFrameNames('presentMeter', { start: 3, end: 3, prefix: 'present', suffix: '.png'}),
        })
        this.presentMeter.anims.create({
          key: 'present2',
          frameRate: 1,
          frames: this.presentMeter.anims.generateFrameNames('presentMeter', { start: 2, end: 2, prefix: 'present', suffix: '.png'}),
        })
        this.presentMeter.anims.create({
          key: 'present1',
          frameRate: 1,
          frames: this.presentMeter.anims.generateFrameNames('presentMeter', { start: 1, end: 1, prefix: 'present', suffix: '.png'}),
        })
        this.presentMeter.anims.create({
          key: 'present0',
          frameRate: 1,
          frames: this.presentMeter.anims.generateFrameNames('presentMeter', { start: 0, end: 0, prefix: 'present', suffix: '.png'}),
        })
    }
      
}