{
	"textures": [
		{
			"image": "ornament.png",
			"format": "RGBA8888",
			"size": {
				"w": 121,
				"h": 60
			},
			"scale": 1,
			"frames": [
				{
					"filename": "ornamentBlue4.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 30,
						"h": 24
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 16,
						"w": 30,
						"h": 8
					},
					"frame": {
						"x": 2,
						"y": 2,
						"w": 30,
						"h": 8
					}
				},
				{
					"filename": "ornamentGreen4.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 30,
						"h": 24
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 15,
						"w": 30,
						"h": 9
					},
					"frame": {
						"x": 35,
						"y": 2,
						"w": 30,
						"h": 9
					}
				},
				{
					"filename": "ornamentPurple4.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 30,
						"h": 24
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 15,
						"w": 30,
						"h": 9
					},
					"frame": {
						"x": 68,
						"y": 2,
						"w": 30,
						"h": 9
					}
				},
				{
					"filename": "ornamentBlue2.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 30,
						"h": 24
					},
					"spriteSourceSize": {
						"x": 6,
						"y": 2,
						"w": 18,
						"h": 22
					},
					"frame": {
						"x": 101,
						"y": 2,
						"w": 18,
						"h": 22
					}
				},
				{
					"filename": "ornamentBlue3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 30,
						"h": 24
					},
					"spriteSourceSize": {
						"x": 1,
						"y": 7,
						"w": 26,
						"h": 17
					},
					"frame": {
						"x": 2,
						"y": 13,
						"w": 26,
						"h": 17
					}
				},
				{
					"filename": "ornamentGreen3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 30,
						"h": 24
					},
					"spriteSourceSize": {
						"x": 1,
						"y": 7,
						"w": 26,
						"h": 17
					},
					"frame": {
						"x": 31,
						"y": 14,
						"w": 26,
						"h": 17
					}
				},
				{
					"filename": "ornamentPurple3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 30,
						"h": 24
					},
					"spriteSourceSize": {
						"x": 1,
						"y": 7,
						"w": 26,
						"h": 17
					},
					"frame": {
						"x": 60,
						"y": 14,
						"w": 26,
						"h": 17
					}
				},
				{
					"filename": "ornamentGreen2.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 30,
						"h": 24
					},
					"spriteSourceSize": {
						"x": 6,
						"y": 2,
						"w": 18,
						"h": 22
					},
					"frame": {
						"x": 89,
						"y": 27,
						"w": 18,
						"h": 22
					}
				},
				{
					"filename": "ornamentPurple2.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 30,
						"h": 24
					},
					"spriteSourceSize": {
						"x": 6,
						"y": 2,
						"w": 18,
						"h": 22
					},
					"frame": {
						"x": 2,
						"y": 33,
						"w": 18,
						"h": 22
					}
				},
				{
					"filename": "ornamentBlue1.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 30,
						"h": 24
					},
					"spriteSourceSize": {
						"x": 6,
						"y": 0,
						"w": 18,
						"h": 24
					},
					"frame": {
						"x": 23,
						"y": 34,
						"w": 18,
						"h": 24
					}
				},
				{
					"filename": "ornamentGreen1.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 30,
						"h": 24
					},
					"spriteSourceSize": {
						"x": 6,
						"y": 0,
						"w": 18,
						"h": 24
					},
					"frame": {
						"x": 44,
						"y": 34,
						"w": 18,
						"h": 24
					}
				},
				{
					"filename": "ornamentPurple1.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 30,
						"h": 24
					},
					"spriteSourceSize": {
						"x": 6,
						"y": 0,
						"w": 18,
						"h": 24
					},
					"frame": {
						"x": 65,
						"y": 34,
						"w": 18,
						"h": 24
					}
				}
			]
		}
	],
	"meta": {
		"app": "https://www.codeandweb.com/texturepacker",
		"version": "3.0",
		"smartupdate": "$TexturePacker:SmartUpdate:9c44f8a4ce97624ef6391d8fc8afd6d9:4506f65d6de32e2befa7623d54128ede:803376b668f87742c8857fa6d1e36906$"
	}
}
