{
	"textures": [
		{
			"image": "santa.png",
			"format": "RGBA8888",
			"size": {
				"w": 472,
				"h": 1019
			},
			"scale": 1,
			"frames": [
				{
					"filename": "run4.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 120,
						"h": 174
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 120,
						"h": 169
					},
					"frame": {
						"x": 1,
						"y": 1,
						"w": 120,
						"h": 169
					}
				},
				{
					"filename": "run10.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 115,
						"h": 174
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 115,
						"h": 169
					},
					"frame": {
						"x": 1,
						"y": 172,
						"w": 115,
						"h": 169
					}
				},
				{
					"filename": "run5.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 115,
						"h": 174
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 115,
						"h": 162
					},
					"frame": {
						"x": 123,
						"y": 1,
						"w": 115,
						"h": 162
					}
				},
				{
					"filename": "run3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 105,
						"h": 174
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 4,
						"w": 105,
						"h": 170
					},
					"frame": {
						"x": 1,
						"y": 343,
						"w": 105,
						"h": 170
					}
				},
				{
					"filename": "run11.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 113,
						"h": 174
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 113,
						"h": 161
					},
					"frame": {
						"x": 240,
						"y": 1,
						"w": 113,
						"h": 161
					}
				},
				{
					"filename": "run9.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 103,
						"h": 174
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 4,
						"w": 103,
						"h": 170
					},
					"frame": {
						"x": 1,
						"y": 515,
						"w": 103,
						"h": 170
					}
				},
				{
					"filename": "run6.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 100,
						"h": 174
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 3,
						"w": 100,
						"h": 170
					},
					"frame": {
						"x": 1,
						"y": 687,
						"w": 100,
						"h": 170
					}
				},
				{
					"filename": "run7.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 86,
						"h": 174
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 15,
						"w": 86,
						"h": 159
					},
					"frame": {
						"x": 1,
						"y": 859,
						"w": 86,
						"h": 159
					}
				},
				{
					"filename": "run12.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 102,
						"h": 174
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 6,
						"w": 102,
						"h": 164
					},
					"frame": {
						"x": 355,
						"y": 1,
						"w": 102,
						"h": 164
					}
				},
				{
					"filename": "jump10.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 82,
						"h": 179
					},
					"spriteSourceSize": {
						"x": 1,
						"y": 0,
						"w": 80,
						"h": 177
					},
					"frame": {
						"x": 108,
						"y": 343,
						"w": 80,
						"h": 177
					}
				},
				{
					"filename": "idle1.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 91,
						"h": 167
					},
					"spriteSourceSize": {
						"x": 2,
						"y": 1,
						"w": 88,
						"h": 166
					},
					"frame": {
						"x": 106,
						"y": 522,
						"w": 88,
						"h": 166
					}
				},
				{
					"filename": "idle5.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 91,
						"h": 167
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 91,
						"h": 167
					},
					"frame": {
						"x": 103,
						"y": 690,
						"w": 91,
						"h": 167
					}
				},
				{
					"filename": "crouch1.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 100,
						"h": 167
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 32,
						"w": 100,
						"h": 135
					},
					"frame": {
						"x": 89,
						"y": 859,
						"w": 100,
						"h": 135
					}
				},
				{
					"filename": "idle4.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 91,
						"h": 167
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 89,
						"h": 167
					},
					"frame": {
						"x": 118,
						"y": 172,
						"w": 89,
						"h": 167
					}
				},
				{
					"filename": "jump11.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 82,
						"h": 179
					},
					"spriteSourceSize": {
						"x": 1,
						"y": 0,
						"w": 80,
						"h": 178
					},
					"frame": {
						"x": 209,
						"y": 165,
						"w": 80,
						"h": 178
					}
				},
				{
					"filename": "jump1.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 95,
						"h": 179
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 19,
						"w": 95,
						"h": 160
					},
					"frame": {
						"x": 190,
						"y": 345,
						"w": 95,
						"h": 160
					}
				},
				{
					"filename": "idle2.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 91,
						"h": 167
					},
					"spriteSourceSize": {
						"x": 2,
						"y": 1,
						"w": 87,
						"h": 166
					},
					"frame": {
						"x": 196,
						"y": 507,
						"w": 87,
						"h": 166
					}
				},
				{
					"filename": "idle3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 91,
						"h": 167
					},
					"spriteSourceSize": {
						"x": 1,
						"y": 1,
						"w": 87,
						"h": 166
					},
					"frame": {
						"x": 196,
						"y": 675,
						"w": 87,
						"h": 166
					}
				},
				{
					"filename": "run2.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 91,
						"h": 174
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 10,
						"w": 91,
						"h": 164
					},
					"frame": {
						"x": 291,
						"y": 167,
						"w": 91,
						"h": 164
					}
				},
				{
					"filename": "jump8.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 88,
						"h": 179
					},
					"spriteSourceSize": {
						"x": 1,
						"y": 17,
						"w": 86,
						"h": 162
					},
					"frame": {
						"x": 384,
						"y": 167,
						"w": 86,
						"h": 162
					}
				},
				{
					"filename": "run1.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 88,
						"h": 174
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 9,
						"w": 88,
						"h": 165
					},
					"frame": {
						"x": 285,
						"y": 507,
						"w": 88,
						"h": 165
					}
				},
				{
					"filename": "run8.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 90,
						"h": 174
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 11,
						"w": 90,
						"h": 163
					},
					"frame": {
						"x": 285,
						"y": 674,
						"w": 90,
						"h": 163
					}
				},
				{
					"filename": "jump7.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 82,
						"h": 179
					},
					"spriteSourceSize": {
						"x": 1,
						"y": 1,
						"w": 80,
						"h": 147
					},
					"frame": {
						"x": 287,
						"y": 345,
						"w": 80,
						"h": 147
					}
				},
				{
					"filename": "jump9.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 88,
						"h": 179
					},
					"spriteSourceSize": {
						"x": 1,
						"y": 50,
						"w": 86,
						"h": 129
					},
					"frame": {
						"x": 191,
						"y": 859,
						"w": 86,
						"h": 129
					}
				},
				{
					"filename": "jump2.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 97,
						"h": 179
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 24,
						"w": 96,
						"h": 116
					},
					"frame": {
						"x": 375,
						"y": 333,
						"w": 96,
						"h": 116
					}
				},
				{
					"filename": "jump6.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 88,
						"h": 179
					},
					"spriteSourceSize": {
						"x": 1,
						"y": 5,
						"w": 86,
						"h": 122
					},
					"frame": {
						"x": 375,
						"y": 451,
						"w": 86,
						"h": 122
					}
				},
				{
					"filename": "crouch2.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 100,
						"h": 167
					},
					"spriteSourceSize": {
						"x": 3,
						"y": 76,
						"w": 93,
						"h": 91
					},
					"frame": {
						"x": 375,
						"y": 575,
						"w": 93,
						"h": 91
					}
				},
				{
					"filename": "crouch3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 100,
						"h": 167
					},
					"spriteSourceSize": {
						"x": 3,
						"y": 76,
						"w": 93,
						"h": 91
					},
					"frame": {
						"x": 377,
						"y": 668,
						"w": 93,
						"h": 91
					}
				},
				{
					"filename": "jump4.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 93,
						"h": 179
					},
					"spriteSourceSize": {
						"x": 1,
						"y": 17,
						"w": 88,
						"h": 72
					},
					"frame": {
						"x": 377,
						"y": 761,
						"w": 88,
						"h": 72
					}
				},
				{
					"filename": "jump3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 102,
						"h": 179
					},
					"spriteSourceSize": {
						"x": 1,
						"y": 26,
						"w": 101,
						"h": 91
					},
					"frame": {
						"x": 285,
						"y": 839,
						"w": 101,
						"h": 91
					}
				},
				{
					"filename": "jump5.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 82,
						"h": 179
					},
					"spriteSourceSize": {
						"x": 1,
						"y": 0,
						"w": 80,
						"h": 88
					},
					"frame": {
						"x": 388,
						"y": 835,
						"w": 80,
						"h": 88
					}
				}
			]
		}
	],
	"meta": {
		"app": "https://www.codeandweb.com/texturepacker",
		"version": "3.0",
		"smartupdate": "$TexturePacker:SmartUpdate:784e81f8f47aeed4ab017049e49c132f:2f5604997c4f06d1ee294eb5ed7df85d:3095c57fbbd330068b206a9f43d27330$"
	}
}
