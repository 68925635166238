import Phaser from 'phaser'

import Game from './scenes/Game'
import UI from './scenes/UI'
import Menu from './scenes/Menu'

const config: Phaser.Types.Core.GameConfig = {
	type: Phaser.AUTO,
	width: 1920*.75,
	height: 1080*.75,
    // pixelArt: true,
	scale: {
        parent: 'yippee-ki-yay',

        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        min: {
            
        },
        max: {
            
        },
        zoom: 1,
    },
	
	physics: {
		default: 'matter',
		matter: {
			// debug: true,
		},
	},
	// scene: [Game, Menu, UI]
	scene: [Menu, Game, UI]
}

export default new Phaser.Game(config)
