import Phaser from "phaser"
const pixelFont = require("../../public/assets/bitmapFonts/pixelFont.png")
const pixelFontXml = require("url:../../public/assets/bitmapFonts/pixelFont.xml")
const mSanta = require("../../public/assets/menuSanta.png")
const logo = require("../../public/assets/logo.png")
// @ts-ignore
import menu from 'url:../../public/assets/sounds/themes/menu.mp3'
// @ts-ignore
import select from 'url:../../public/assets/sounds/fx/select.wav'

const text = {
  menu: {
      x: 225,
      y: 500,
      lines: [
          { text: "To Start the Game, Press Enter/Return", tabCount: 0 },
          { text: "To View Gameplay Controls, Press Spacebar", tabCount: 0 },
          { text: "To Toggle Mute/UnMute, Press 'M'", tabCount: 0 },
          { text: "To View a YouTube Video of the game, Press ESC", tabCount: 0 },
        ],
  },
  controls: {
      x: 150,
      y: 115,
      lines: [
          { text: "GamePlay Controls:", tabCount: 0 },
          { text: "", tabCount: 0 },
          { text: "*Spacebar: Jump", tabCount: 3 },
          { text: "*Left-Arrow Key: Left", tabCount: 3 },
          { text: "*Right-Arrow Key: Right", tabCount: 3 },
          { text: "*Down-Arrow Key:", tabCount: 3 },
          { text: "- While Jumping: Fast Fall", tabCount: 4 },
          { text: "- While Standing: Crouch", tabCount: 4 },
          { text: "*Enter/Return:", tabCount: 3 },
          { text: "- Quit Gameplay", tabCount: 4 },
          { text: "", tabCount: 0 },
          { text: "To Return to the Main Menu, Press Spacebar", tabCount: 0, color: 0xffcc33  }
      ],
  },
  gameover: {
      x: 175,
      y: 150,
      lines: [
          { text: "Game Over", tabCount: 8, color: 0xff0033 },
          { text: "", tabCount: 0 },
          { text: "Thanks for playing!", tabCount: 6 },
          { text: "", tabCount: 0 },
          { text: "Wishing you and your loved ones", tabCount: 3 },
          { text: "a safe and happy holiday season.", tabCount: 3 },
          { text: "Cheers to 2022!  -Matt OB", tabCount: 3 },
          { text: "", tabCount: 0 },
          { text: "", tabCount: 0 },
          { text: "To Retry, Refresh your browser", tabCount: 3, color: 0xffcc33 },
      ],
  },
  winner: {
      x: 175,
      y: 150,
      lines: [
          { text: "You Did It! Way to go!", tabCount: 5, color: 0x33ff99 },
          { text: "", tabCount: 0 },
          { text: " Thanks for playing!", tabCount: 5 },
          { text: "", tabCount: 0 },
          { text: "Wishing you and your loved ones", tabCount: 3 },
          { text: "a safe and happy holiday season.", tabCount: 3 },
          { text: "Cheers to 2022!  -Matt OB", tabCount: 3 },
          { text: "", tabCount: 0 },
          { text: "", tabCount: 0 },
          // { text: "To play again, Refresh your browser", tabCount: 3 },
          { text: "To Retry, Press Spacebar", tabCount: 3, color: 0xffcc33  },
      ]
  }
} 
const lineHeight = 50;
const tab = 50
const fontSize = 20
const volume = .6

export default class Menu extends Phaser.Scene {
  private muted = false
  private currentScreen = ''
  private currentScreenText: Array<Phaser.GameObjects.BitmapText> = []
  private santaImg?: Phaser.GameObjects.Image
  private logoImg?: Phaser.GameObjects.Image
  private rec?: Phaser.GameObjects.Rectangle
  private dead = false
  private winner = false

  private drawFullSceenText (textKey: string) {
    this.currentScreen = textKey
    let controlCount = 0
    if(this.currentScreenText.length) {
      this.currentScreenText.forEach(txt  => {
        txt.destroy()
      });
    }
    text[textKey].lines.forEach(control => {
      const temp = this.add.bitmapText(text[textKey].x+tab*control.tabCount,
        text[textKey].y+(lineHeight * controlCount), 'pixelFont', control.text, fontSize)
      const temp2 = this.add.bitmapText(text[textKey].x+tab*control.tabCount +6,
        text[textKey].y+(lineHeight * controlCount) +6, 'pixelFont', control.text, fontSize)
      const temp3 = this.add.bitmapText(text[textKey].x+tab*control.tabCount +3,
        text[textKey].y+(lineHeight * controlCount) +3, 'pixelFont', control.text, fontSize)
      temp.setTint(0x000000)
      temp3.setTint(control.color ? control.color : 0xffffff)
      temp2.setTint(0x000000)
      this.currentScreenText.push(temp);
      this.currentScreenText.push(temp2);
      this.currentScreenText.push(temp3);
      controlCount++;
    })
  }
  constructor() {
    super('menu')
  }

  private handleKeyUp(event) {
    if(!this.dead && !this.winner){
      if (event.keyCode === Phaser.Input.Keyboard.KeyCodes.SPACE){
        if(this.currentScreen === 'menu') {
          this.drawFullSceenText('controls')
          this.santaImg?.setVisible(false)
          this.logoImg?.setVisible(false)
          this.rec?.setVisible(false)
        } else if(this.currentScreen === 'controls') {
          this.drawFullSceenText('menu')
          this.santaImg?.setVisible(true)
          this.logoImg?.setVisible(true)
          this.rec?.setVisible(true)
        }
      } else if (event.keyCode === Phaser.Input.Keyboard.KeyCodes.M){
        console.log('m')
        this.sound.volume = this.sound.volume === 0 ? .6 : 0;
      } else if (event.keyCode === Phaser.Input.Keyboard.KeyCodes.ENTER){
        if(this.currentScreen === 'menu') {
          this.scene.start('gameplay', {
            mute: this.muted 
          });
        }
      } else if (event.keyCode === Phaser.Input.Keyboard.KeyCodes.ESC){
        window.location.href = "https://www.youtube.com/watch?v=K_0vrTII74c&t=105s";
      } 
    }
  }
  
  init(data) {
    this.muted = false
    this.dead = (data && data.dead)
    this.winner = (data && data.winner)
    this.input.keyboard.on('keyup', this.handleKeyUp, this)
  }

  preload() {
    this.load.bitmapFont('pixelFont', pixelFont, pixelFontXml);
    this.load.audio('menu', menu)
    this.load.audio('select', select)
    this.load.image('mSanta', mSanta)
    this.load.image('logo', logo)
  }

  create() {
    if(this.dead){
      this.drawFullSceenText('gameover')
    } else if(this.winner) {
      this.drawFullSceenText('winner')
    } else {
        // menu
        this.sound.volume = volume
        this.sound.play('menu', {
          loop: true
        })
        this.santaImg = this.santaImg = this.add.image(345, 450, 'mSanta')
        this.santaImg.setRotation(-.15)
        this.rec = this.add.rectangle(775, 588, 1125, 205, 0x000000).setAlpha(.75).setStrokeStyle(4, 0xffffff);
        this.drawFullSceenText('menu')
        this.logoImg = this.add.image(843, 320, 'logo')

        //  We need 1 extra pointer, as we only get 1 by default
        // this.input.addPointer(1);
    }
  }

  // update() {
  //   if (this.input.pointer1.isDown || this.input.pointer2.isDown)
  //   {
  //     console.log('ta-ta-ta-ta-ta-ta-touch me')
  //   // } else if (this.input.mouse.
  //   }
  // }
}