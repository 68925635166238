{"frames": {

"ginger1.png":
{
	"frame": {"x":44,"y":158,"w":42,"h":78},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":2,"w":42,"h":78},
	"sourceSize": {"w":48,"h":80}
},
"ginger2.png":
{
	"frame": {"x":87,"y":79,"w":42,"h":77},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":3,"w":42,"h":77},
	"sourceSize": {"w":48,"h":80}
},
"ginger3.png":
{
	"frame": {"x":134,"y":158,"w":38,"h":78},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":2,"w":38,"h":78},
	"sourceSize": {"w":48,"h":80}
},
"ginger4.png":
{
	"frame": {"x":90,"y":1,"w":40,"h":76},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":4,"w":40,"h":76},
	"sourceSize": {"w":48,"h":80}
},
"ginger5.png":
{
	"frame": {"x":132,"y":1,"w":41,"h":76},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":4,"w":41,"h":76},
	"sourceSize": {"w":48,"h":80}
},
"ginger6.png":
{
	"frame": {"x":131,"y":79,"w":43,"h":77},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":3,"w":43,"h":77},
	"sourceSize": {"w":48,"h":80}
},
"ginger7.png":
{
	"frame": {"x":1,"y":1,"w":48,"h":75},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":5,"w":48,"h":75},
	"sourceSize": {"w":48,"h":80}
},
"ginger8.png":
{
	"frame": {"x":1,"y":78,"w":43,"h":76},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":4,"w":43,"h":76},
	"sourceSize": {"w":48,"h":80}
},
"ginger9.png":
{
	"frame": {"x":1,"y":156,"w":41,"h":77},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":3,"w":41,"h":77},
	"sourceSize": {"w":48,"h":80}
},
"ginger10.png":
{
	"frame": {"x":51,"y":1,"w":37,"h":76},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":4,"w":37,"h":76},
	"sourceSize": {"w":48,"h":80}
},
"ginger11.png":
{
	"frame": {"x":46,"y":79,"w":39,"h":77},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":3,"w":39,"h":77},
	"sourceSize": {"w":48,"h":80}
},
"ginger12.png":
{
	"frame": {"x":88,"y":158,"w":44,"h":78},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":2,"w":44,"h":78},
	"sourceSize": {"w":48,"h":80}
}},
"meta": {
	"app": "https://www.codeandweb.com/texturepacker",
	"version": "1.0",
	"image": "ginger.png",
	"format": "RGBA8888",
	"size": {"w":175,"h":237},
	"scale": "1",
	"smartupdate": "$TexturePacker:SmartUpdate:0286eee859c46238ae9625b3d545600a:ef7b426e4572a1a3a46f9a6b533009de:60aeafff7e4d7e47406057ef17a8ac07$"
}
}
