{"frames": {

"health0.png":
{
	"frame": {"x":1,"y":1,"w":72,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":72,"h":74},
	"sourceSize": {"w":72,"h":74}
},
"health1.png":
{
	"frame": {"x":75,"y":1,"w":72,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":72,"h":74},
	"sourceSize": {"w":72,"h":74}
},
"health2.png":
{
	"frame": {"x":149,"y":1,"w":72,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":72,"h":74},
	"sourceSize": {"w":72,"h":74}
},
"health3.png":
{
	"frame": {"x":223,"y":1,"w":72,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":72,"h":74},
	"sourceSize": {"w":72,"h":74}
}},
"meta": {
	"app": "https://www.codeandweb.com/texturepacker",
	"version": "1.0",
	"image": "health.png",
	"format": "RGBA8888",
	"size": {"w":296,"h":76},
	"scale": "1",
	"smartupdate": "$TexturePacker:SmartUpdate:d2fb55927e0de597e4c90e0a266f6168:cfaf7ac66c10df346000b681bc9ee7d8:4ab86db837ea5a7156439e8a327f2d27$"
}
}
