{"frames": {

"fire1.png":
{
	"frame": {"x":1,"y":1,"w":48,"h":21},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":48,"h":21},
	"sourceSize": {"w":48,"h":21}
},
"fire2.png":
{
	"frame": {"x":51,"y":1,"w":47,"h":21},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":47,"h":21},
	"sourceSize": {"w":48,"h":21}
},
"fire3.png":
{
	"frame": {"x":100,"y":1,"w":42,"h":21},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":42,"h":21},
	"sourceSize": {"w":48,"h":21}
},
"fire4.png":
{
	"frame": {"x":144,"y":1,"w":40,"h":20},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":40,"h":20},
	"sourceSize": {"w":48,"h":21}
},
"fire5.png":
{
	"frame": {"x":186,"y":1,"w":40,"h":19},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":40,"h":19},
	"sourceSize": {"w":48,"h":21}
},
"fire6.png":
{
	"frame": {"x":88,"y":24,"w":37,"h":17},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":37,"h":17},
	"sourceSize": {"w":48,"h":21}
},
"fire7.png":
{
	"frame": {"x":1,"y":24,"w":43,"h":17},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":43,"h":17},
	"sourceSize": {"w":48,"h":21}
},
"fire8.png":
{
	"frame": {"x":46,"y":24,"w":40,"h":17},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":40,"h":17},
	"sourceSize": {"w":48,"h":21}
},
"fire9.png":
{
	"frame": {"x":127,"y":24,"w":43,"h":16},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":43,"h":16},
	"sourceSize": {"w":48,"h":21}
},
"fire10.png":
{
	"frame": {"x":186,"y":22,"w":37,"h":15},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":37,"h":15},
	"sourceSize": {"w":48,"h":21}
}},
"meta": {
	"app": "https://www.codeandweb.com/texturepacker",
	"version": "1.0",
	"image": "fire.png",
	"format": "RGBA8888",
	"size": {"w":227,"h":42},
	"scale": "1",
	"smartupdate": "$TexturePacker:SmartUpdate:e500f76eb4332cbf9fe6ce407fdd36e8:7afb28a4674d3d55aec8e8dc5ee3a24e:fe85b518f19a08db92d89ee48ba7169f$"
}
}
