{
	"textures": [
		{
			"image": "present.png",
			"format": "RGBA8888",
			"size": {
				"w": 1007,
				"h": 707
			},
			"scale": 1,
			"frames": [
				{
					"filename": "carebear7.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 1,
						"w": 64,
						"h": 134
					},
					"frame": {
						"x": 1,
						"y": 1,
						"w": 64,
						"h": 134
					}
				},
				{
					"filename": "dino7.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 1,
						"w": 64,
						"h": 134
					},
					"frame": {
						"x": 1,
						"y": 137,
						"w": 64,
						"h": 134
					}
				},
				{
					"filename": "doll7.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 1,
						"w": 64,
						"h": 134
					},
					"frame": {
						"x": 1,
						"y": 273,
						"w": 64,
						"h": 134
					}
				},
				{
					"filename": "robot7.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 1,
						"w": 64,
						"h": 134
					},
					"frame": {
						"x": 1,
						"y": 409,
						"w": 64,
						"h": 134
					}
				},
				{
					"filename": "carebear8.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 4,
						"w": 64,
						"h": 131
					},
					"frame": {
						"x": 1,
						"y": 545,
						"w": 64,
						"h": 131
					}
				},
				{
					"filename": "dino8.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 4,
						"w": 64,
						"h": 131
					},
					"frame": {
						"x": 67,
						"y": 1,
						"w": 64,
						"h": 131
					}
				},
				{
					"filename": "doll8.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 4,
						"w": 64,
						"h": 131
					},
					"frame": {
						"x": 67,
						"y": 134,
						"w": 64,
						"h": 131
					}
				},
				{
					"filename": "robot8.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 4,
						"w": 64,
						"h": 131
					},
					"frame": {
						"x": 67,
						"y": 267,
						"w": 64,
						"h": 131
					}
				},
				{
					"filename": "carebear6.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 8,
						"y": 4,
						"w": 59,
						"h": 131
					},
					"frame": {
						"x": 67,
						"y": 400,
						"w": 59,
						"h": 131
					}
				},
				{
					"filename": "dino6.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 8,
						"y": 4,
						"w": 59,
						"h": 131
					},
					"frame": {
						"x": 67,
						"y": 533,
						"w": 59,
						"h": 131
					}
				},
				{
					"filename": "carebear25.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 97,
						"w": 64,
						"h": 38
					},
					"frame": {
						"x": 67,
						"y": 666,
						"w": 64,
						"h": 38
					}
				},
				{
					"filename": "dino25.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 97,
						"w": 64,
						"h": 38
					},
					"frame": {
						"x": 67,
						"y": 666,
						"w": 64,
						"h": 38
					}
				},
				{
					"filename": "doll25.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 97,
						"w": 64,
						"h": 38
					},
					"frame": {
						"x": 67,
						"y": 666,
						"w": 64,
						"h": 38
					}
				},
				{
					"filename": "robot25.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 97,
						"w": 64,
						"h": 38
					},
					"frame": {
						"x": 67,
						"y": 666,
						"w": 64,
						"h": 38
					}
				},
				{
					"filename": "doll6.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 8,
						"y": 4,
						"w": 59,
						"h": 131
					},
					"frame": {
						"x": 128,
						"y": 400,
						"w": 59,
						"h": 131
					}
				},
				{
					"filename": "robot6.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 8,
						"y": 4,
						"w": 59,
						"h": 131
					},
					"frame": {
						"x": 128,
						"y": 533,
						"w": 59,
						"h": 131
					}
				},
				{
					"filename": "carebear1.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 12,
						"y": 97,
						"w": 51,
						"h": 38
					},
					"frame": {
						"x": 133,
						"y": 666,
						"w": 51,
						"h": 38
					}
				},
				{
					"filename": "dino1.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 12,
						"y": 97,
						"w": 51,
						"h": 38
					},
					"frame": {
						"x": 133,
						"y": 666,
						"w": 51,
						"h": 38
					}
				},
				{
					"filename": "doll1.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 12,
						"y": 97,
						"w": 51,
						"h": 38
					},
					"frame": {
						"x": 133,
						"y": 666,
						"w": 51,
						"h": 38
					}
				},
				{
					"filename": "robot1.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 12,
						"y": 97,
						"w": 51,
						"h": 38
					},
					"frame": {
						"x": 133,
						"y": 666,
						"w": 51,
						"h": 38
					}
				},
				{
					"filename": "carebear5.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 20,
						"w": 64,
						"h": 115
					},
					"frame": {
						"x": 133,
						"y": 1,
						"w": 64,
						"h": 115
					}
				},
				{
					"filename": "dino5.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 20,
						"w": 64,
						"h": 115
					},
					"frame": {
						"x": 133,
						"y": 118,
						"w": 64,
						"h": 115
					}
				},
				{
					"filename": "doll5.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 20,
						"w": 64,
						"h": 115
					},
					"frame": {
						"x": 133,
						"y": 235,
						"w": 64,
						"h": 115
					}
				},
				{
					"filename": "robot5.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 20,
						"w": 64,
						"h": 115
					},
					"frame": {
						"x": 189,
						"y": 352,
						"w": 64,
						"h": 115
					}
				},
				{
					"filename": "doll12.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 23,
						"w": 64,
						"h": 112
					},
					"frame": {
						"x": 189,
						"y": 469,
						"w": 64,
						"h": 112
					}
				},
				{
					"filename": "doll13.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 23,
						"w": 64,
						"h": 112
					},
					"frame": {
						"x": 189,
						"y": 583,
						"w": 64,
						"h": 112
					}
				},
				{
					"filename": "doll14.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 23,
						"w": 64,
						"h": 112
					},
					"frame": {
						"x": 199,
						"y": 1,
						"w": 64,
						"h": 112
					}
				},
				{
					"filename": "doll24.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 23,
						"w": 64,
						"h": 112
					},
					"frame": {
						"x": 199,
						"y": 115,
						"w": 64,
						"h": 112
					}
				},
				{
					"filename": "doll10.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 24,
						"w": 64,
						"h": 111
					},
					"frame": {
						"x": 199,
						"y": 229,
						"w": 64,
						"h": 111
					}
				},
				{
					"filename": "doll16.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 24,
						"w": 64,
						"h": 111
					},
					"frame": {
						"x": 255,
						"y": 342,
						"w": 64,
						"h": 111
					}
				},
				{
					"filename": "doll19.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 24,
						"w": 64,
						"h": 111
					},
					"frame": {
						"x": 255,
						"y": 455,
						"w": 64,
						"h": 111
					}
				},
				{
					"filename": "doll22.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 24,
						"w": 64,
						"h": 111
					},
					"frame": {
						"x": 255,
						"y": 568,
						"w": 64,
						"h": 111
					}
				},
				{
					"filename": "doll11.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 265,
						"y": 1,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "doll15.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 265,
						"y": 113,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "doll23.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 265,
						"y": 225,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "robot10.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 321,
						"y": 337,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "robot11.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 321,
						"y": 449,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "robot12.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 321,
						"y": 561,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "robot13.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 331,
						"y": 1,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "robot14.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 331,
						"y": 113,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "robot15.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 331,
						"y": 225,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "robot16.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 387,
						"y": 337,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "robot17.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 387,
						"y": 449,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "robot18.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 387,
						"y": 561,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "robot19.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 397,
						"y": 1,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "robot20.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 397,
						"y": 113,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "robot21.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 397,
						"y": 225,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "robot9.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 397,
						"y": 225,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "robot22.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 453,
						"y": 337,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "robot23.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 453,
						"y": 449,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "robot24.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 25,
						"w": 64,
						"h": 110
					},
					"frame": {
						"x": 453,
						"y": 561,
						"w": 64,
						"h": 110
					}
				},
				{
					"filename": "doll17.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 26,
						"w": 64,
						"h": 109
					},
					"frame": {
						"x": 463,
						"y": 1,
						"w": 64,
						"h": 109
					}
				},
				{
					"filename": "doll18.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 26,
						"w": 64,
						"h": 109
					},
					"frame": {
						"x": 463,
						"y": 112,
						"w": 64,
						"h": 109
					}
				},
				{
					"filename": "doll20.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 26,
						"w": 64,
						"h": 109
					},
					"frame": {
						"x": 463,
						"y": 223,
						"w": 64,
						"h": 109
					}
				},
				{
					"filename": "doll21.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 26,
						"w": 64,
						"h": 109
					},
					"frame": {
						"x": 519,
						"y": 334,
						"w": 64,
						"h": 109
					}
				},
				{
					"filename": "doll9.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 26,
						"w": 64,
						"h": 109
					},
					"frame": {
						"x": 519,
						"y": 334,
						"w": 64,
						"h": 109
					}
				},
				{
					"filename": "carebear10.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 519,
						"y": 445,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "carebear11.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 519,
						"y": 552,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "carebear12.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 529,
						"y": 1,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "carebear13.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 529,
						"y": 108,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "carebear15.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 529,
						"y": 215,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "carebear16.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 585,
						"y": 322,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "carebear17.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 585,
						"y": 429,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "carebear18.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 585,
						"y": 536,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "carebear19.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 595,
						"y": 1,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "carebear20.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 595,
						"y": 108,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "carebear21.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 595,
						"y": 215,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "carebear4.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 31,
						"w": 73,
						"h": 104
					},
					"frame": {
						"x": 661,
						"y": 1,
						"w": 73,
						"h": 104
					}
				},
				{
					"filename": "dino4.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 31,
						"w": 73,
						"h": 104
					},
					"frame": {
						"x": 736,
						"y": 1,
						"w": 73,
						"h": 104
					}
				},
				{
					"filename": "doll4.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 31,
						"w": 73,
						"h": 104
					},
					"frame": {
						"x": 811,
						"y": 1,
						"w": 73,
						"h": 104
					}
				},
				{
					"filename": "robot4.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 31,
						"w": 73,
						"h": 104
					},
					"frame": {
						"x": 886,
						"y": 1,
						"w": 73,
						"h": 104
					}
				},
				{
					"filename": "carebear3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 3,
						"y": 40,
						"w": 68,
						"h": 95
					},
					"frame": {
						"x": 661,
						"y": 107,
						"w": 68,
						"h": 95
					}
				},
				{
					"filename": "dino3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 3,
						"y": 40,
						"w": 68,
						"h": 95
					},
					"frame": {
						"x": 731,
						"y": 107,
						"w": 68,
						"h": 95
					}
				},
				{
					"filename": "doll3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 3,
						"y": 40,
						"w": 68,
						"h": 95
					},
					"frame": {
						"x": 801,
						"y": 107,
						"w": 68,
						"h": 95
					}
				},
				{
					"filename": "robot3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 3,
						"y": 40,
						"w": 68,
						"h": 95
					},
					"frame": {
						"x": 871,
						"y": 107,
						"w": 68,
						"h": 95
					}
				},
				{
					"filename": "carebear22.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 941,
						"y": 107,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "carebear23.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 661,
						"y": 204,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "carebear24.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 727,
						"y": 204,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "carebear9.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 793,
						"y": 204,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "dino12.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 859,
						"y": 204,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "dino13.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 925,
						"y": 214,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "dino14.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 661,
						"y": 311,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "dino19.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 727,
						"y": 311,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "dino24.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 30,
						"w": 64,
						"h": 105
					},
					"frame": {
						"x": 793,
						"y": 311,
						"w": 64,
						"h": 105
					}
				},
				{
					"filename": "carebear14.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 31,
						"w": 64,
						"h": 104
					},
					"frame": {
						"x": 859,
						"y": 311,
						"w": 64,
						"h": 104
					}
				},
				{
					"filename": "dino10.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 31,
						"w": 64,
						"h": 104
					},
					"frame": {
						"x": 925,
						"y": 321,
						"w": 64,
						"h": 104
					}
				},
				{
					"filename": "dino16.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 31,
						"w": 64,
						"h": 104
					},
					"frame": {
						"x": 859,
						"y": 417,
						"w": 64,
						"h": 104
					}
				},
				{
					"filename": "dino18.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 31,
						"w": 64,
						"h": 104
					},
					"frame": {
						"x": 925,
						"y": 427,
						"w": 64,
						"h": 104
					}
				},
				{
					"filename": "dino20.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 31,
						"w": 64,
						"h": 104
					},
					"frame": {
						"x": 651,
						"y": 533,
						"w": 64,
						"h": 104
					}
				},
				{
					"filename": "dino22.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 31,
						"w": 64,
						"h": 104
					},
					"frame": {
						"x": 651,
						"y": 418,
						"w": 64,
						"h": 104
					}
				},
				{
					"filename": "dino17.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 32,
						"w": 64,
						"h": 103
					},
					"frame": {
						"x": 717,
						"y": 533,
						"w": 64,
						"h": 103
					}
				},
				{
					"filename": "dino21.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 32,
						"w": 64,
						"h": 103
					},
					"frame": {
						"x": 717,
						"y": 418,
						"w": 64,
						"h": 103
					}
				},
				{
					"filename": "dino9.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 32,
						"w": 64,
						"h": 103
					},
					"frame": {
						"x": 717,
						"y": 418,
						"w": 64,
						"h": 103
					}
				},
				{
					"filename": "dino11.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 33,
						"w": 64,
						"h": 102
					},
					"frame": {
						"x": 783,
						"y": 418,
						"w": 64,
						"h": 102
					}
				},
				{
					"filename": "dino15.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 33,
						"w": 64,
						"h": 102
					},
					"frame": {
						"x": 783,
						"y": 522,
						"w": 64,
						"h": 102
					}
				},
				{
					"filename": "carebear2.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 12,
						"y": 55,
						"w": 51,
						"h": 80
					},
					"frame": {
						"x": 783,
						"y": 626,
						"w": 51,
						"h": 80
					}
				},
				{
					"filename": "dino2.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 12,
						"y": 55,
						"w": 51,
						"h": 80
					},
					"frame": {
						"x": 836,
						"y": 626,
						"w": 51,
						"h": 80
					}
				},
				{
					"filename": "doll2.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 12,
						"y": 55,
						"w": 51,
						"h": 80
					},
					"frame": {
						"x": 849,
						"y": 533,
						"w": 51,
						"h": 80
					}
				},
				{
					"filename": "robot2.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 12,
						"y": 55,
						"w": 51,
						"h": 80
					},
					"frame": {
						"x": 889,
						"y": 615,
						"w": 51,
						"h": 80
					}
				},
				{
					"filename": "dino23.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 73,
						"h": 135
					},
					"spriteSourceSize": {
						"x": 5,
						"y": 33,
						"w": 64,
						"h": 102
					},
					"frame": {
						"x": 942,
						"y": 533,
						"w": 64,
						"h": 102
					}
				}
			]
		}
	],
	"meta": {
		"app": "https://www.codeandweb.com/texturepacker",
		"version": "3.0",
		"smartupdate": "$TexturePacker:SmartUpdate:1052dd98b6cf4f4d433d9fa12affa7c7:a6f43350a31c2dc3701a77a6125aabda:867ed37c6a454869c969a3275e65fa09$"
	}
}
